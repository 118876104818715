import React from 'react';

import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import PageIcon from '@material-ui/icons/MenuBook';
import FileIcon from '@material-ui/icons/Folder';
import OrgIcon from '@material-ui/icons/Business';
import EmailIcon from '@material-ui/icons/Send';

const PageAdminIndex: React.FunctionComponent = (): React.ReactElement => {
    React.useEffect(() => {
        document.title = "Home - ACF Admin";
    });
    
    return (
        <React.Fragment>
            <Typography variant="h4">ACF Site Admin Dashboard</Typography>
            <TutorBox showKey="tutor-intro" showValue={-1} closeValue={100} nextValue={0}>
                <Typography variant="h5">Welcome to the ACF Admin Console</Typography>
                <Typography variant="body1">
                    This tutorial will guide you through the basics of using the admin console.
                    If you are already familiar with the admin console, press Close below, otherwise
                    press Next to get started.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-intro" showValue={0} closeValue={100} nextValue={1}>
                <Typography variant="h5">Menu Button</Typography>
                <Typography variant="body1">
                    We'll start with the basics. The highlighted button in the
                    top left opens the main navigation menu. You'll notice that
                    the items in the navigation menu are always visible. Opening
                    the menu simply expands these items to explain what the
                    different icons mean. You can still use the icons for
                    navigation when the menu is closed.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-intro" showValue={1} closeValue={100} nextValue={2}>
                <Typography variant="h5">Menu items</Typography>
                <Typography variant="body1">
                    There are four main items in the navigation menu, which
                    correlate to the main functions of the Admin Console.
                    These items are Pages, Files, Organizations, and Mail.
                    The four main items are always present in the navigation
                    menu. When you navigate to one of these items, new menu
                    items may appear that are relevant to that subject.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-intro" showValue={2} closeValue={100} nextValue={3}>
                <Typography variant="h5"><PageIcon/>&nbsp;Pages</Typography>
                <Typography variant="body1">
                    The Pages button will take you to where you can manage pages
                    seen by users visiting andersonchildrensfoundation.org
                    (or acfgrants.org).
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-intro" showValue={3} closeValue={100} nextValue={4}>
                <Typography variant="h5"><FileIcon/>&nbsp;Files</Typography>
                <Typography variant="body1">
                    The Files button will take you to the File Manager, where
                    you can view and manage files used in the user-facing site.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-intro" showValue={4} closeValue={100} nextValue={5}>
                <Typography variant="h5"><OrgIcon/>&nbsp;Organizations</Typography>
                <Typography variant="body1">
                    The Organizations button leads to the Organization management
                    page. This is where all of the Organizations funded by the
                    ACF are tracked. 
                </Typography>
                <Typography variant="body1">
                    The Admin Console tracks information about Organizations,
                    the Projects they run, and the Funding they receive from the
                    ACF. This is done in order to automatically manage the more
                    tedious parts of the user-facing site.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-intro" showValue={5} closeValue={100} nextValue={6}>
                <Typography variant="h5"><EmailIcon/>&nbsp;Mail</Typography>
                <Typography variant="body1">
                    The Mail system allows sheduling emails that are sent to
                    large lists of recipients. This is used to keep potential
                    and current grant applicants informed of the current grant
                    application cycle.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-intro" showValue={6} closeValue={100} nextValue={7}>
                <Typography variant="h5">Search</Typography>
                <Typography variant="body1">
                    The Search Bar can be used to quickly find certain resources,
                    such as Pages, Files, and Organizations.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-intro" showValue={7} closeValue={100} nextValue={8}>
                <Typography variant="h5">Cloud</Typography>
                <Typography variant="body1">
                    The Cloud icon indicates the state of the backend server.
                    If you don't see a solid cloud here, then most of the Admin
                    Console will not function. If you see error messages when
                    This space is filled by a loading indicator, just wait for
                    the cloud icon to appear, and refresh the page. If there
                    is a cloud that is crossed out, please refresh the page, and
                    contact nico@andersonchildrensfoundation.org
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-intro" showValue={8} closeValue={100} nextValue={9}>
                <Typography variant="h5">User</Typography>
                <Typography variant="body1">
                    The user button provides acess to user related functions,
                    such as viewing the current user, viewing all users, signing
                    out of the Console, and accessing the web-mail interface.
                    The web-mail interface is the only way to change your ACF
                    email account password.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-intro" showValue={9} closeValue={100} nextValue={10}>
                <Typography variant="h5">Help</Typography>
                <Typography variant="body1">
                    The help button provides access to the Console's built-in
                    documentation. If you need more detailed information than
                    what is provided in the tutorials, or if you encounter an
                    error message, you can check the documentation. The help
                    button will automatically open context-relevant documentation.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-intro" showValue={10} closeValue={20}>
                <Typography variant="body1">
                    That's the end of the introductory tutorial! When you
                    navigate to another page, the relevant tutorial for that
                    page will automatically begin. Choose any link in the
                    navigation menu to get started!
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

const useAppBarStyles = makeStyles((theme: Theme) => 
    createStyles({
        
    })
);

const AppBar: React.FunctionComponent = (): React.ReactElement => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const classes = useAppBarStyles();
    
    return (
        <div></div>
    );
};

const NavBar: React.FunctionComponent = (): React.ReactElement => {
    return (
        <List>
        </List>
    );
};

export { PageAdminIndex as default, AppBar, NavBar };

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { RenderPages as RenderPagesDialog } from '@acf-site/react-sdk/components/Pages/Ui/RenderPages';
import { SendEmail as SendEmailDialog } from '@acf-site/react-sdk/components/Mail/SendEmail';

import TaskBrowsePage from './../../pages/admin/common/taskBrowse';
import TaskDetailPage from './../../pages/admin/common/taskDetail';

const CommonPageRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment>
            <RenderPagesDialog/>
            <SendEmailDialog/>
            <Switch>
                <Route path="/admin/common/tasks/" render={() => <TaskBrowsePage/>}/>
                <Route path="/admin/common/task/" render={() => <TaskDetailPage/>}/>
            </Switch>
        </React.Fragment>
    );
};

const CommonAppBarRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <Switch>
            
        </Switch>
    );
};

const CommonNavDrawerRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment>
        </React.Fragment>
    );
};

export {
    CommonPageRoutes as PageRoutes,
    CommonAppBarRoutes as AppBarRoutes,
    CommonNavDrawerRoutes as NavDrawerRoutes
};

import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { DocletHeading } from '@acf-site/react-sdk/components/Common/Docs/DocletHeading';
import { DocletBody } from '@acf-site/react-sdk/components/Common/Docs/DocletBody';

import { useStyles as useDocStyles } from './';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export const DocOrgs: React.FunctionComponent = (): React.ReactElement => {
    const docClasses = useDocStyles();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb" className={docClasses.breadcrumbs}>
                    <Link component={RouterLink} to="/admin/docs/">Docs</Link>
                    <Typography color="textPrimary">Organizations</Typography>
                </Breadcrumbs>
                <Typography variant="body1" gutterBottom>
                    
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Contents:</Typography>
                <ul>
                    <li><Link component={RouterLink} to="#orgs">Organizations</Link></li>
                    <li><Link component={RouterLink} to="#projects">Projects</Link></li>
                    <li><Link component={RouterLink} to="#funding">Project Funding</Link></li>
                    <li><Link component={RouterLink} to="#dirs">Root Directories</Link></li>
                    <li><Link component={RouterLink} to="#page">Organization Page</Link></li>
                </ul>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="orgs">Organizations</Typography>
                <Typography variant="body1" gutterBottom>
                    Organizations in the ACF Admin Console represent non-profit
                    organizations that have received funding from the ACF.
                    Organizations are containers for Projects, which in turn
                    contain Project Funding records.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Information from Organizations can be inserted into Pages
                    using the Reference functionanlity of the Text Component.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    When the details of an Organization are changed, the Pages
                    referencing the Organization are automatically rendered as
                    necessary.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Creating Organizations</Typography>
                <Typography variant="body1" gutterBottom>
                    The Create Organizaiton dialog (accessed using the + button
                    in the Organization Browser) includes a number of input
                    fields, but in most cases, only one is of interest.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Organization Name is the name of the new Organization.
                    Organization names must be unique.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Create new filder / Use existing folder and Organization
                    Folder are used to determine whether a new folder should
                    be created to store files related to the Organization, or
                    if an existing folder should be used instead. Usually, a new
                    folder should be created, which is the default choice. Only
                    use an existing folder if absolutely necessary.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Create new Page / Use existing Page and Organization Page
                    are used to determine whether a new Page should be created
                    for the Organization, or if an existing Page should be used.
                    Usually, a new Page should be created.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Letting the system create folders and Pages for
                    Organizations automatically helps maintain organization of
                    the file and page systems.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="projects">Projects</Typography>
                <Typography variant="body1" gutterBottom>
                    Projects represent the individual projects that the ACF
                    provides funding for. Information from projects can be
                    inserted in Pages using the Reference functionality of
                    the Text Component, or using the Projects By Year Component.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Creating Projects</Typography>
                <Typography variant="body1" gutterBottom>
                    The Create Project dialog (accessed via the + button in the
                    Organization Detail page) has three fields, which are
                    similar to those in the Create Organization dialog.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Project Title is the title of the new Project to create.
                    Projeect titles must be unique within their parent
                    Organization.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Create new folder / Use existing folder and Project folder
                    are used (as in the Create Organization dialog) to indicate
                    whether a new folder should be created to store Files
                    related to the Project, or if an existing folder should be
                    linked to the new Project. It is preferred to let the system
                    create a new folder.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="funding">Project Funding</Typography>
                <Typography variant="body1" gutterBottom>
                    Project Funding records record how much funding the ACF
                    provided for a given Project in a grant year. Grant years
                    are represented by the year in which the grant application
                    was submitted. When a Project Funding record is updated,
                    created or destroyed, any necessary Pages are automatically
                    rendered to reflect the new information.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="dirs">Root Directories</Typography>
                <Typography variant="body1" gutterBottom>
                    Organizations, Projects, and Project Funding records all
                    have an associated "Root Directory" (directory is a synonym)
                    for folder. All of these folders are system folders (see
                    files documentation) and cannot be manipulated directly.
                    When an Organization, Project, or Project Funding record is
                    deleted, the corresponding folders become detatched from the
                    deleted object and can then be deleted. Organization Root
                    Directories are created in the Organizations folder by
                    default. Project Root Directories are created in the Root
                    Directory of their parent Organization.  Project Funding
                    directories are created in the root directory of their
                    parent Project.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The intention of these folders is to facilitate maintenance
                    of a well organized file system. Any files related to an
                    Organization, Project, or specific year of a Project, should
                    be kept in the corresponding folder.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Note that all files stored in the Admin Console are publicly
                    accessible.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="page">Organization Page</Typography>
                <Typography variant="body1" gutterBottom>
                    Organizations always have a Page associated with them. The
                    Organization Page is meant to have content related only to
                    that Organization and its Projects. Any Page Components that
                    reference an Organization or Project may include a link to
                    the Organization Page. Entries in the Projects by Year
                    Component include links to the Organizaiton Pages of the
                    Organizations of the Projects funded in the selected year.
                </Typography>
            </Grid>
        </Grid>
    );
};

export const DocletOrgBrowser: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Organization Browser"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/orgs">Organizations Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The Organization browser shows an alphabetically sorted list of
                    every Organization on record as having been funded by the ACF.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Click on an Organization to see its details and Projects.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Use the + button at the bottom right to create a new
                    Organization.
                </Typography>
            </DocletBody>
        </div>
    );
};

export const DocletOrgDetail: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Organization Detail"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/orgs">Organizations Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The Organizaiton Detail page shows detailed information about an
                    Organization.
                    <ul>
                        <li>
                            Name is the name of the Organization as it is shown in
                            the Organization Browser and in rendered Pages.
                        </li>
                        <li>
                            Enabled is whether the Organization is enabled. Disabled
                            Organizations are excluded from any Page Components that
                            reference lists of Organizations, Projects, or Project
                            Funding records.
                        </li>
                        <li>
                            Date Created, Created By, Date Updated, and Updated By
                            show who created the Organization, who last modified it,
                            and when.
                        </li>
                        <li>
                            Home Page is a link to the Page where information about
                            the Organization and its Projects should be written.
                        </li>
                        <li>
                            Root Directory is a link to the folder where files
                            related to the Organization should be stored.
                        </li>
                    </ul>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The Projects panel shows a list of all of the Projects
                    run by the Organization, as well as when and by whom they were
                    last edited.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Create a new Project for the Organization using the + button at
                    the bottom right.
                </Typography>
            </DocletBody>
        </div>
    );
};

export const DocletProjectDetail: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Project Detail"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/orgs">Organizations Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The Project Detail page shows detailed information regarding a
                    Project and its Funding records.
                    <ul>
                        <li>
                            Name is the name of the Project.
                        </li>
                        <li>
                            Enabled determines whether the Project is enabled.
                            Disabled Projects do not appear in generated lists of
                            Projects.
                        </li>
                        <li>
                            Date Created, Created By, Date Updated, and Updated By
                            show when the Project was created and when it was last
                            updated, and by whom.
                        </li>
                        <li>
                            Root Directory is a link to the folder where files
                            related to the Project should be stored.
                        </li>
                    </ul>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The Project Funding panel shows all of the Project Funding
                    records for the Project. Funding records can be edited or
                    deleted using the pencil and 'x' icons on the right of each
                    record. Funding records can be created using the + button
                    at the top right of the funding panel.
                </Typography>
            </DocletBody>
        </div>
    );
};

import React from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { DataImportTask } from '@acf-site/react-sdk/components/Orgs/DataImport/DataImportTask';
import { StartIndexing } from '@acf-site/client-sdk/lib/Orgs/DataImport/ImportEntry/actions/StartIndexing';
import { StopIndexing } from '@acf-site/client-sdk/lib/Orgs/DataImport/ImportEntry/actions/StopIndexing';

const PageDataImport: React.FunctionComponent = (): React.ReactElement => {
    useTitle("Data Import");
    
    const dispatch = useDispatch();
    
    React.useEffect(() => {
        dispatch(StartIndexing());
        
        return () => {
            dispatch(StopIndexing());
        };
    }, [dispatch]);
    
    return (
        <React.Fragment>
            <DataImportTask/>
        </React.Fragment>
    );
};

export default withRouter(PageDataImport);

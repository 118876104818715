import React from 'react';

import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import { useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { Mail } from '@acf-site/react-sdk/components/Mail/Mail';

import Typography from '@material-ui/core/Typography';

const PageMail: React.FunctionComponent = (): React.ReactElement => {
    useTitle("Mail");
    
    return (
        <React.Fragment>
            <Mail/>
            <TutorBox showKey="tutle-mail-intro" showValue={-1} closeValue={100} nextValue={0}>
                <Typography variant="h5">Mail</Typography>
                <Typography variant="body1">
                    This is the landing page for the Mail subsystem. The Mail
                    system is used to send mass emails to large numbers of
                    recipients. The Mail system is composed of four resources,
                    which are shown in an overview here.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutle-mail-intro" showValue={0} closeValue={100} nextValue={1}>
                <Typography variant="h5">Emails</Typography>
                <Typography variant="body1">
                    Emails are individual messages that can be sent to
                    recipients. Like Pages, Emails have Drafts and Templates.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutle-mail-intro" showValue={1} closeValue={100} nextValue={2}>
                <Typography variant="h5">Email Templates</Typography>
                <Typography variant="body1">
                    Email Templates are like Page Templates, but for Emails.
                    They are used to simplify the process of ensuring that
                    multiple Emails have similar content or styling.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutle-mail-intro" showValue={2} closeValue={100} nextValue={3}>
                <Typography variant="h5">Mailing Lists</Typography>
                <Typography variant="body1">
                    Mailing Lists store lists of recipient email addresses to
                    send emails to. When sending an Email, the destination is
                    a Mailing List, not an individual email address.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutle-mail-intro" showValue={3} closeValue={4}>
                <Typography variant="h5">Email Sends</Typography>
                <Typography variant="body1">
                    Email "Sends" or "Send Records" represent a past or
                    scheduled delivery of an Email to a Mailing List.
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default PageMail;

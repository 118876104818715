import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import { useIntHash, useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { Send } from '@acf-site/core-sdk/lib/Mail/Send';
import { Root } from '@acf-site/client-sdk/lib/types';
import { selectSend } from '@acf-site/client-sdk/lib/Mail/Send/reducer';

import { SendDetail } from '@acf-site/react-sdk/components/Mail/SendDetail';

import Typography from '@material-ui/core/Typography';

const PageSendDetail: React.FunctionComponent = (): React.ReactElement => {
    const sid: number = useIntHash();
    const send: Send | null = useSelector((state: Root) => selectSend(state, sid));
    useTitle(send === null ? `Email Send ${sid}` : `Email Send ${(new Date(send.sendDate)).toLocaleString()}`);
    
    return (
        <React.Fragment>
            <SendDetail sid={sid}/>
            <TutorBox showKey="tutor-send-detail" showValue={-1} closeValue={100} nextValue={0} prereqKey="tutor-intro" prereqValue={32}>
                <Typography variant="h5">Send Record Detail</Typography>
                <Typography variant="body1">
                    The Send Record Detail page shows details of a Send Record.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-send-detail" showValue={0} closeValue={100} nextValue={1}>
                <Typography variant="h5">Delete / Cancel</Typography>
                <Typography variant="body1" gutterBottom>
                    The Delete button can be used to delete a Send Record.
                </Typography>
                <Typography variant="body1">
                    If the Send is scheduled to happen in the future, the Send
                    can be canceled. If the Send is scheduled to happen in less
                    than a few minutes from when it is canceled, the cancelation
                    may fail, and the Email may be sent anyway.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-send-detail" showValue={1} closeValue={2}>
                <Typography variant="h5">Stats</Typography>
                <Typography variant="body1" gutterBottom>
                    The Stats panel shows send statistics, including every
                    address to shich the Email was sent. For each destination
                    address, the stats track whether the Email was sent, whether
                    it has been delivered, and whether the Email bounced.
                    Note that stat tracking is not perfect and does not always
                    show the email state accurately.
                </Typography>
                <Typography variant="body1">
                    For email addresses that bounce, the address is
                    automatically removed from the Mailing List that the Send
                    sent the Email to.
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default withRouter(PageSendDetail);

import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { TutorBuble } from '@acf-site/react-sdk/components/Common/Docs/TutorBuble';
import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import { useStyles, useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { CreatePage } from '@acf-site/client-sdk/lib/Pages/Ui/CreatePage/sagas/CreatePage';

import { PageBrowser } from '@acf-site/react-sdk/components/Pages/Ui/PageBrowser';

import Typography from '@material-ui/core/Typography';

import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';

import PageIcon from '@material-ui/icons/InsertDriveFile';
import DisabledIcon from '@material-ui/icons/InsertDriveFileOutlined';
import WorkingIcon from '@material-ui/icons/Sync';
import ArchivedIcon from '@material-ui/icons/Archive';
import DelayedIcon from '@material-ui/icons/PauseCircleOutline';

const PagePagesBrowse: React.FunctionComponent = (): React.ReactElement => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    
    const hash: string = history.location.hash;
    
    useTitle("Pages");
    
    type onNavHandler = (path: string) => void;
    const onNav: onNavHandler = React.useCallback<onNavHandler>((path: string) => {
        history.push(`#${path}`);
        
    }, [history]);
    
    type onPageHandler = (pid: number) => void;
    const onPage: onPageHandler = React.useCallback<onPageHandler>((pid: number) => {
        history.push(`/admin/pages/page/#${pid}`);
        
    }, [history]);
    
    const path: string = (() => {
        if (hash === undefined) return "";
        if (hash.startsWith('#/')) return hash.substring(2);
        if (hash.startsWith("#")) return hash.substring(1);
        if (hash.startsWith('/')) return hash.substring(1);
        return hash;
        
    })();
    
    return (
        <React.Fragment>
            <PageBrowser
                path={path}
                onPageSelected={onPage}
                onDirSelected={onNav}
                />
            <div className={classes.fabContainer}>
                <Fab color="primary" aria-label="Create Page" title="Create Page" onClick={() => dispatch(CreatePage(path))}>
                    <AddIcon/>
                    <TutorBuble showKey="tutor-pages-intro" showValue={7}/>
                </Fab>
            </div>
            <TutorBox showKey="tutor-pages-intro" showValue={-1} closeValue={100} nextValue={0} prereqKey="tutor-intro" prereqValue={29}>
                <Typography variant="h5">Page Browser</Typography>
                <Typography variant="body1">
                    This is the Page Browser. The Page Browser is important when
                    managing the user-facing website.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-pages-intro" showValue={0} closeValue={100} nextValue={1}>
                <Typography variant="h5">Folders</Typography>
                <Typography variant="body1">
                    Pages are arranged in a tree structure like a file system.
                    The folders that you can navigate to always appear at the top
                    of the Page Browser, and are identified by a folder icon.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-pages-intro" showValue={1} closeValue={100} nextValue={2}>
                <Typography variant="h5">Pages</Typography>
                <Typography variant="body1">
                    Below any Folders, the Page Browser shows all of the Pages
                    in the current folder. Some Pages will have a variation
                    of the Page's name next to the name of the Page. This is a
                    "safe" version of the page name that is used in the page's URL.
                </Typography>
                <Typography variant="body1">
                    Pages can be in different states, which are indicated by the
                    Page's icon on the left of the Page Browser.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-pages-intro" showValue={2} closeValue={100} nextValue={3}>
                <Typography variant="h5"><PageIcon/>&nbsp;Ready</Typography>
                <Typography variant="body1">
                    Pages in the Ready state are ready to be viewed by users.
                    Any page that needs to be visible to users should be in the
                    Ready state.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-pages-intro" showValue={3} closeValue={100} nextValue={4}>
                <Typography variant="h5"><DisabledIcon/>&nbsp;Disabled</Typography>
                <Typography variant="body1">
                    Disabled Pages cannot be viewed by users. Pages that are
                    not ready to be viewed, or that are no longer relevant should
                    be disabled.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-pages-intro" showValue={4} closeValue={100} nextValue={5}>
                <Typography variant="h5"><WorkingIcon/>&nbsp;Queued / Rendering</Typography>
                <Typography variant="body1">
                    Pages in the Queued or Rendering states are waiting to have
                    updates to their content processed. These states are a side
                    effect of the way that the Admin Console handles changes to
                    page content. Depending on the number of queued pages,
                    pages in the queue should be rendered within a few minutes
                    or less. Rendering can take anywhere from seconds to minutes
                    for a single page.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-pages-intro" showValue={5} closeValue={100} nextValue={7}>
                <Typography variant="h5"><DelayedIcon/>&nbsp;Delayed</Typography>
                <Typography variant="body1">
                    Sometimes pages will appear in the Delayed state. Pages are
                    only delayed when it is expected that a large number of
                    Pages will need to be updated. Delayed pages change to the
                    Queued, or Rendering state once all of the updated Pages
                    have been Delayed.
                </Typography>
            </TutorBox>
            {/* This box is skipped*/}
            <TutorBox showKey="tutor-pages-intro" showValue={6} closeValue={100} nextValue={7}>
                <Typography variant="h5"><ArchivedIcon/>&nbsp;Archived</Typography>
                <Typography variant="body1">
                    Archived Pages are like Disabled Pages, but they are ignored
                    by processes that act on all Pages.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-pages-intro" showValue={7} closeValue={100} nextValue={8}>
                <Typography variant="h5">Create Page</Typography>
                <Typography variant="body1">
                    The highlighted "+" button allows you to create a new Page.
                    Check the documentation for more information on creating
                    Pages.
                    {/* TODO: link to documentation page on CreatePage? */}
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-pages-intro" showValue={8} closeValue={9}>
                <Typography variant="h5">Navigation Menu</Typography>
                <Typography variant="body1">
                    In the main Navigation Menu on the left of the page, you'll
                    see that there are two items that are not present on other
                    pages. The first simply opens the Page Browser to the site
                    root. The second opens the Page Template Browser.
                </Typography>
                <Typography variant="body1">
                    Try clicking on a Page or opening the Page Template Browser.
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default withRouter(PagePagesBrowse);

import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { TutorBuble } from '@acf-site/react-sdk/components/Common/Docs/TutorBuble';
import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import { useStyles, useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { CreateOrg } from '@acf-site/client-sdk/lib/Orgs/CreateOrg/sagas/CreateOrg';

import { OrgBrowser } from '@acf-site/react-sdk/components/Orgs/OrgBrowser';

import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';

const PageOrgsBrowse: React.FunctionComponent = (): React.ReactElement => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    useTitle("Organizations");
    
    const onOrgSelected = React.useCallback((oid: number) => {
        history.push(`/admin/orgs/organization/#${oid}`);
        
    }, [history]);
    
    const [page, setPage] = React.useState<number>(0);
    
    return (
        <React.Fragment>
            <OrgBrowser
                limit={100}
                offset={page * 100}
                onOrgSelected={onOrgSelected}
                onPageTurn={setPage}
            />
            <div className={classes.fabContainer}>
                <Fab color="primary" aria-label="Create Organization" title="Create Organization" onClick={() => dispatch(CreateOrg(undefined, true))}>
                    <AddIcon/>
                    <TutorBuble showKey="tutor-orgs-intro" showValue={1}/>
                </Fab>
            </div>
            <TutorBox showKey="tutor-orgs-intro" showValue={-1} closeValue={100} nextValue={0} prereqKey="tutor-intro" prereqValue={29}>
                <Typography variant="h5">Organization Browser</Typography>
                <Typography variant="body1">
                    This is the Organization Browser. The Organizaiton Browser
                    and related pages are used to track the organizations funded
                    by ACF, the Projects that are funded, and how much funding
                    they receive.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-orgs-intro" showValue={0} closeValue={100} nextValue={1}>
                <Typography variant="h5">Edit</Typography>
                <Typography variant="body1">
                    The Edit button allows enabling and disabling of
                    Organizations in bulk. It doesn't work very well, and
                    usually isn't needed.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-orgs-intro" showValue={1} closeValue={2}>
                <Typography variant="h5">Create Organization</Typography>
                <Typography variant="body1">
                    The + Button can be used to create a new Organization.
                    Check the relevant Documentatino for details on creating
                    Organizations.
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default withRouter(PageOrgsBrowse);

import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useIntHash, useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import { Root } from '@acf-site/client-sdk/lib/types';

import { Template } from '@acf-site/core-sdk/lib/Pages/Template';
import { selectTemplate } from '@acf-site/client-sdk/lib/Pages/Template/reducer';

import { ListTemplates } from '@acf-site/client-sdk/lib/Pages/Template/sagas/ListTemplates';

import { TemplateDetail } from '@acf-site/react-sdk/components/Pages/Ui/TemplateDetail';

import Typography from '@material-ui/core/Typography';

const PageTemplateDetail: React.FunctionComponent = (): React.ReactElement => {
    const dispatch = useDispatch();
    const tid: number = useIntHash();
    const template: Template | null = useSelector((state: Root) => selectTemplate(state, tid));
    useTitle(template === null ? `Page Template ${tid}` : template.name);
    
    const [fetched, setFetched] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (!fetched) {
            setFetched(true);
            dispatch(ListTemplates([], 100, 0, true));
        }
        
    }, [dispatch, fetched, setFetched]);
    
    return (
        <React.Fragment>
            <TemplateDetail tid={tid}/>
            <TutorBox showKey="tutor-pages-template-editor" showValue={-1} closeValue={1} prereqKey="Tutor-intro" prereqValue={50}>
                <Typography variant="h5">Template Editor</Typography>
                <Typography variant="body1">
                    This is the Page Template Editor. Editing Page Templates 
                    differas from editing Page Drafts, Email Drafts, or Email
                    Templates only in that Page Templates do not have Drafts.
                    Saving a Page Template is like publishing an Email Template
                    Draft.
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default withRouter(PageTemplateDetail);

import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import { EmailDraft } from '@acf-site/core-sdk/lib/Mail/EmailDraft';

import { useIntHash, useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { Root } from '@acf-site/client-sdk/lib/types';
import { selectEmailDraft } from '@acf-site/client-sdk/lib/Mail/EmailDraft/reducer';

import { EmailDraftDetail } from '@acf-site/react-sdk/components/Mail/EmailDraftDetail';

import Typography from '@material-ui/core/Typography';

const PageEmailDraftDetail: React.FunctionComponent = (): React.ReactElement => {
    const edid: number = useIntHash();
    const draft: EmailDraft | null = useSelector((state: Root) => selectEmailDraft(state, edid));
    useTitle(draft === null ? `Email Draft ${edid}` : draft.name);
    
    return (
        <React.Fragment>
            <EmailDraftDetail edid={edid}/>
            <TutorBox showKey="tutor-email-draft" showValue={-1} closeValue={100} nextValue={0} prereqKey="tutor-intro" prereqValue={59}>
                <Typography variant="h5">Email Draft Editor</Typography>
                <Typography variant="body1">
                    This is the Email Draft Editor. The editor is mostly the
                    same for Page Drafts, Page Templates, Emails, and Email
                    Templates.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-email-draft" showValue={0} closeValue={1}>
                <Typography variant="h5">Email Template</Typography>
                <Typography variant="body1">
                    On the left of the Email Draft Editor is the Email Template
                    selector. The template selector is used to choose an Email
                    Template to be used when rendering the Email/Draft.
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default withRouter(PageEmailDraftDetail);

import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { TutorBuble } from '@acf-site/react-sdk/components/Common/Docs/TutorBuble';
import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import { useStyles, useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { CreateTemplate } from '@acf-site/client-sdk/lib/Pages/Ui/CreateTemplate/sagas/CreateTemplate';

import { TemplateBrowser } from '@acf-site/react-sdk/components/Pages/Ui/TemplateBrowser';

import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';

const PageTemplatesBrowse: React.FunctionComponent = (): React.ReactElement => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    useTitle("Page Templates");
    
    const handleSelected = React.useCallback((tid: number) => {
        history.push(`/admin/pages/template/#${tid}`);
        
    }, [history]);
    
    return (
        <React.Fragment>
            <TemplateBrowser onTemplateSelected={handleSelected}/>
            <div className={classes.fabContainer}>
                <Fab
                    color="primary"
                    aria-label="Create Template"
                    title="Create Template"
                    onClick={() => dispatch(CreateTemplate())}
                    >
                    <AddIcon/>
                    <TutorBuble showKey="tutor-pages-templates" showValue={0}/>
                </Fab>
            </div>
            <TutorBox showKey="tutor-pages-templates" showValue={-1} closeValue={100} nextValue={0} prereqKey="tutor-intro" prereqValue={29}>
                <Typography variant="h5">Page Template Browser</Typography>
                <Typography variant="body1">
                    This is the Page Template Browser. Page Templates allow many
                    Pages to share content and design without having to manually
                    maintain the shared data.
                </Typography>
                <Typography variant="body1">
                    Page Templates work differntly from Pages, Emails and Email
                    Templates, in that they do not have Drafts. There is only
                    one version of a Page Template, which is the published
                    version. This may change in the future.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-pages-templates" showValue={0} closeValue={1}>
                <Typography variant="h5">Create Template</Typography>
                <Typography variant="body1">
                    The + button allows creation of new Page Templates. Check
                    the relevant Documenation for details on creating and using
                    Page Templates.
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default withRouter(PageTemplatesBrowse);

import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import { Root } from '@acf-site/client-sdk/lib/types';

import { User } from '@acf-site/core-sdk/lib/Auth/User';
import { selectUser } from '@acf-site/client-sdk/lib/Auth/User/reducer';
import { selectStateMe as selectMe } from '@acf-site/client-sdk/lib/Auth/State/reducer';

import { CreateUser } from '@acf-site/client-sdk/lib/Auth/CreateUser/sagas/CreateUser';

import { UserBrowser } from '@acf-site/react-sdk/components/Auth/UserBrowser';

import { useStyles, useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';

import AddUserIcon from '@material-ui/icons/PersonAdd';

const PageUserBrowse: React.FunctionComponent = () : React.ReactElement => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    useTitle("Users");
    
    const me: string | null = useSelector((root: Root) => selectMe(root));
    const user: User | null = useSelector((root: Root) => selectUser(root, me));
    
    const onUserSelected = React.useCallback((uid: string) => {
        history.push(`/admin/auth/user/#${uid}`);
        
    }, [history]);
    
    return (
        <React.Fragment>
            <UserBrowser
                onUserSelected={onUserSelected}
            />
            <div className={classes.fabContainer}>
                <Zoom in={(user !== null && user.super_admin)}>
                    <Fab color="primary" aria-label="Create User" title="Create User" onClick={React.useCallback(() => dispatch(CreateUser(true)), [dispatch])}>
                        <AddUserIcon/>
                    </Fab>
                </Zoom>
            </div>
            <TutorBox showKey="tutor-users-intro" showValue={-1} closeValue={100} nextValue={0} prereqKey="tutor-intro" prereqValue={29}>
                <Typography variant="h5">Users</Typography>
                <Typography variant="body1">
                    This page shows a list of all of the Admin Console Users.
                    Operations on Users are restricted to authorized Users.
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default withRouter(PageUserBrowse);

import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { DocletHeading } from '@acf-site/react-sdk/components/Common/Docs/DocletHeading';
import { DocletBody } from '@acf-site/react-sdk/components/Common/Docs/DocletBody';

import { useStyles } from './index';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export const DocMail: React.FunctionComponent = (): React.ReactElement => {
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
                    <Link component={RouterLink} to="/admin/docs/">Docs</Link>
                    <Typography color="textPrimary">Mail</Typography>
                </Breadcrumbs>
                <Typography variant="h4">Mail</Typography>
                <Typography variant="body1" gutterBottom>
                    The Mail system is used to send mass-emails to large numbers
                    of recipients. Subscribers are tracked in Mailing Lists,
                    which are used as target destinations for Emails. Emails
                    are written in a similar manner to Pages, and have Drafts
                    and Templates. Emails can be scheduled for delivery at a
                    future date/time, and the status of delivery to each
                    destination address is tracked.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Contents:</Typography>
                <ul>
                    <li><Link component={RouterLink} to="#emails">Emails</Link></li>
                    <li><Link component={RouterLink} to="#templates">Email Template</Link></li>
                    <li><Link component={RouterLink} to="#lists">Mailing Lists</Link></li>
                    <li><Link component={RouterLink} to="#sends">Email Sends</Link></li>
                </ul>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
                <Typography variant="h5" id="emails">Emails</Typography>
                <Typography variant="body1" gutterBottom>
                    Emails are the core of the mail system. An Email
                    encapsulates the content and subject of a message that is
                    delivered to the recipients on a Mailing List. Like Pages,
                    the content of an Email is managed using Email Drafts and
                    Email Templates.
                </Typography>
                <Typography variant="h6" className={classes.subheading}>Creating Emails</Typography>
                <Typography variant="body1" gutterBottom>
                    Emails are created using the Create Email dialog, accessed
                    via the + button at the bottom right of the Email Browser.
                    The Create Email dialog has two fields, Email Name and Email
                    Subject. The Email name is only visible within the Admin
                    Console, recipients never see this name. The Email Subject
                    is used as the subject line when the Email is send, and thus
                    is seen by recipients.
                </Typography>
                <Typography variant="h6" className={classes.subheading}>Email Drafts</Typography>
                <Typography variant="body1" gutterBottom>
                    Email Drafts are just what they sound like, drafts of an
                    Email. As with Page Drafts, Email Drafts are meant to be
                    used to prototype changes to an Email's content. When the
                    Email is sent, the content of the Email is determined by
                    which draft was last published. Note that a Draft's content
                    may have been saved without being published, in which case
                    the content of the Email will not accurately reflect that of
                    the Draft, but the Draft will still be marked as being the
                    last Draft to be published.
                </Typography>
                <Typography variant="h6" className={classes.subheading}>Creating Email Drafts</Typography>
                <Typography variant="body1" gutterBottom>
                    The Create Email Draft dialog is accessed  clicking the +
                    button at the top right of the Drafts panel on the Email
                    Detail page. The Email Draft Name field determines the name
                    of the new draft. Draft names should reflect why the draft
                    was created. Clone another Draft and Draft to Clone are used
                    to choose an existing draft to copy the content from when
                    creating the new Draft. Use an Email Template and Email
                    Template are used to choose an Email Template to use when
                    rendering the draft.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
                <Typography variant="h5" id="templates">Email Template</Typography>
                <Typography variant="body1" gutterBottom>
                    Email Template serve the same purpose as Page Templates.
                    They are used to ensure that many Emails follow the same
                    styling pattern, or include some standard content without
                    requiring Email authors to copy or even be aware of the
                    standard styling or content. Unlike Page Templates, Email
                    Template have drafts.
                </Typography>
                <Typography variant="h6" className={classes.subheading}>Creating Email Templates</Typography>
                <Typography variant="body1" gutterBottom>
                    Email Templates are created using the Create Email Template
                    dialog, opened using the + button at the bottom right of the
                    Email Template Browser. The Create Email Template dialog
                    has only a single field, the name to give the new Email 
                    Template.
                </Typography>
                <Typography variant="h6" className={classes.subheading}>Email Template Drafts</Typography>
                <Typography variant="body1" gutterBottom>
                    Email Template Drafts serve the same purpose as Page Drafts
                    and Email Drafts, but for Email Templates. Email Template
                    Drafts allow for multiple working copies of an Email
                    Template to exist simultaneously. Only the published draft
                    is used when rendering Emails using the template.
                </Typography>
                <Typography variant="h6" className={classes.subheading}>Creating Email Template Drafts</Typography>
                <Typography variant="body1" gutterBottom>
                    Email Template Drafts are created using the Create Email
                    Draft dialog, accessed by pressing the + button at the top
                    right of the Drafts panel in the Email Template Detail page.
                    The Create Email Template Draft dialog has three fields.
                    Email Template Draft Name is the name to give the new Email
                    Template Draft. Draft names should regflect the purpose of
                    draft. Clone another Draft and Draft to Clone are used to
                    copy content from an existing Email Template Draft when
                    creating the new Email Template Draft.
                </Typography>
                <Typography variant="h6" className={classes.subheading}>Using Email templates</Typography>
                <Typography variant="body1" gutterBottom>
                    Email Template are used by selecting the template in the
                    Email Draft editor. An Email Template must have been
                    published in order to be used in an Email.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
                <Typography variant="h5" id="lists">Mailing Lists</Typography>
                <Typography variant="body1" gutterBottom>
                    Mailing Lists store the email addresses that Emails are sent
                    to. 
                </Typography>
                <Typography variant="h6" className={classes.subheading}>Creating Mailing Lists</Typography>
                <Typography variant="body1" gutterBottom>
                    Mailing Lists are created using the Create Mailing List
                    dialog, accessed using the + button at the bottom right of
                    the Mailing List Browser. The Create Mailing List dialog
                    has two fields, the name of the new Mailing List, and an
                    optional existing Mailing List to copy recipients from.
                </Typography>
                <Typography variant="h6" className={classes.subheading}>Editing Mailing Lists</Typography>
                <Typography variant="body1" gutterBottom>
                    Editing Mailing Lists is a bit different from editing other
                    objects in the Admin Console. To begin editing a Mailing
                    List, press the pencil button at the top right of the
                    Mailing List Detail page. When editing, a new panel appears
                    to add recipients to the Mailing list. Add recipients by
                    entering email addresses in the "Add Recipients" field.
                    Addresses can be added in bulk by entering them as a comma-
                    or space-separated list. To add multiple batches of
                    addresses, press the + button to the right of the Add
                    Recipients field. To remove recipients from the Mailing
                    List, check the check box next to them in the list of
                    existing recipients. Press save at the top right to save
                    your changes.
                </Typography>
                <Typography variant="h6" className={classes.subheading}>Self Subscribing Recipients</Typography>
                <Typography variant="body1" gutterBottom>
                    To allow users to subscribe themselves to a Mailing List,
                    insert a Subscribe Form component into any Page. See the <Link component={RouterLink} to="/admin/docs/editor#types-subscribe-form">
                    Subscribe Form Component</Link> documentation for details.
                </Typography>
                <Typography variant="h6" className={classes.subheading}>Bounce Handling</Typography>
                <Typography variant="body1" gutterBottom>
                    If an attempt to send an Email to an address results in a
                    hard bounce response (indicating that the destination
                    address does not exist) then the address may be
                    automatically removed from the Mailing List. Such addresses
                    can also be automatically black-listed and cannot be added
                    to a new Mailing List.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
                <Typography variant="h5" id="sends">Email Sends / Sending Emails</Typography>
                <Typography variant="body1" gutterBottom>
                    Email Sends refer to records of when an Email was sent, or
                    a record of the intent to send an Email at some point in
                    the future. Email Sends record information about the sending
                    of the Email, including which Email was/will be sent, when
                    it was/will be sent, the Mailing List to which it was/will
                    be sent, and the delivery status.
                </Typography>
                <Typography variant="h6" className={classes.subheading}>Sending Emails</Typography>
                <Typography variant="body1" gutterBottom>
                    Emails are sent using the Send Email dialog, accessed using
                    the send button at the bottom right of the Send Browser
                    page. The Send Email dialog asks for an Email to send,
                    a Mailing List to send it to, and when to send the Email.
                    To send an Email immediately, check Send Now. To send an
                    Email at a future date/time, uncheck Send Now, and choose a
                    date/time at which to send the Email. The Email will be sent
                    within a few minutes of the selected time.
                </Typography>
                <Typography variant="h6" className={classes.subheading}>Canceling Scheduled Emails</Typography>
                <Typography variant="body1" gutterBottom>
                    If an Email is scheduled to be sent in the future, the Send
                    can be canceled using the Delete button in the Send Detail
                    page. Sends cannot be stopped once they have started, and
                    can only be cancelled if they are scheduled sufficiently
                    later than when they are canceled.
                </Typography>
                <Typography variant="h6" className={classes.subheading}>Delivery Statistics</Typography>
                <Typography variant="body1" gutterBottom>
                    The Delivery Statistics panel on the Send Detail page shows
                    the delivery status for each address that the Email was sent
                    to. Not that the delivery statistics sometimes have issues
                    and don't always reflect the delivery status accurately (for
                    example if every address says "SENT" then there was probably
                    an error tracking the delivery status). Delivery statistics
                    will not function correctly if two Sends occur at the same
                    time, or if one starts within five minutes of another
                    finishing. This is because the Email Send Task overrides
                    the normal delivery status update handling and processes
                    all updates itself. Thus if two sends occur at the same
                    time, then only one will receive delivery status updates.
                    The Email Send Task continues to listen for status updates
                    for up to five minutes after it has finished sending emails.
                </Typography>
            </Grid>
        </Grid>
    );
};

export const DocletMailHome: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Mail"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/mail">Mail Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The Mail system is used to send Emails to large numbers of
                    recipients, organized in Mailing Lists.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The Mail home page shows an overview of all of the mail system
                    resources. This page might be removed later.
                </Typography>
            </DocletBody>
        </div>
    );
};

export const DocletEmailBrowser: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Email Browser"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/mail">Mail Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The Email Browser lists Emails, ordered by age. Thus the newest
                    Emails appear at the top.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Use the + button at the bottom right to create a new Email. See
                    The Mail Documentation for more information.
                </Typography>
            </DocletBody>
        </div>
    );
};

export const DocletEmailDetail: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Email Detail"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/mail">Mail Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The Email Detail page shows detailed information about an Email.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The summary panel shows the usual information about an Email
                    (date created/updated, created/updated by), as well as the Email
                    name, subject, and publish date.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The Email References panl shows any objects (Files, Pages,
                    Organizations, Projects, etc) referenced by the Email content.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The Email Send Records panel shows any Email Sends that indicate
                    the Email was or will be sent.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The Drafts panel shows all of the drafts of the Email. A star
                    icon indicates which draft was last published. The pencil icon
                    can be used to edit the content of the draft. The plus button
                    is used to create a new Email Draft.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The Send Test Email button, next to the Save and Delete buttons
                    can be used to send the Email to a single recipient, for testing
                    purposes.
                </Typography>
            </DocletBody>
        </div>
    );
};

export const DocletEmailEditor: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Email Draft Editor"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/mail">Mail Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/editor">Editor Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The Email Draft Editor is used to edit the content of an Email
                    Draft. Much of the editor functionality is shared with the other
                    editors. See the Editor Documentation for details on the editor.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Unique to the Email Draft Editor are the Email Template picker,
                    and the Send Test Email button. The Email Template picker, found
                    on the left above the Component Palette, is used to select an
                    Email Template to use when rendering the Email Draft. The Send
                    Test Email button is used to send the Email Draft to a single
                    recipient, for testing purposes.
                </Typography>
            </DocletBody>
        </div>
    );
};

export const DocletEmailTemplateBrowser: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Email Template Browser"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/mail">Mail Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The Email Template Browser shows a list of all of the Email
                    Templates. See the Mail Documentation for more information on
                    Emails and Email Templates.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    New Email Templates can be created using the + button at the
                    bottom right. See the Mail Documentation for details on creating
                    Email Templates.
                </Typography>
            </DocletBody>
        </div>
    );
};

export const DocletEmailTemplateDetail: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Email Template Detail"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/mail">Mail Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The Email Template Detail page shows detailed information about
                    a single Email Template.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The summary panel shows the usual information (date
                    created/updated, created/updated by), as well as the Email
                    Template name and last publication date.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The Email Template References panel shows any objects (Files,
                    Pages, Oragnizations, etc.) referenced by the Email Template.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The Drafts panel shows a list of drafts of the Email Template.
                    A star icon indicates which Email Template Draft was last
                    publiched. The pencil icon can be used to edit the Email 
                    Template Draft. The + icon is used to create a new Email
                    Template Draft. Check the Mail Documentation for details on
                    creating Email Template Drafts.
                </Typography>
            </DocletBody>
        </div>
    );
};

export const DocletEmailTemplateEditor: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Email Template Editor"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/mail">Mail Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/editor">Editor Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The Email Template Draft Editor is used to edit the content of
                    Email Template Drafts. Much of the editor is the same as the
                    other editors, so be sure to read the Editor Documentation.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Remember that all Email Templates need to include one and only
                    one Template Fill Component where the content of the Email
                    using the template will be inserted.
                </Typography>
            </DocletBody>
        </div>
    );
};

export const DocletListBrowser: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Mailing List Browser"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/mail">Mail Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The Mailing List Browser shows a list of all Mailing Lists.
                    Read the Mail Documentation for more information on Mailing
                    Lists.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    New Mailing Lists can be created using the + button at the
                    bottom right. Check the Mail Documentation for details on
                    creating Mailing Lists.
                </Typography>
            </DocletBody>
        </div>
    );
};

export const DocletListDetail: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Mailing List Detail"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/mail">Mail Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The Mailing List Detail page shows the contents of a Mailing
                    List. Read the Mail Documentation for more information on
                    Mailing Lists.
                </Typography>
            </DocletBody>
        </div>
    );
};

export const DocletSendBrowser: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Email Send Browser"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/mail">Mail Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The Email Send Browser shows a list of Email Send records,
                    sorted by age. Email Send records (Email Sends or just Sends)
                    represent a record of, or an intent to send an Email to the
                    recipients on a Mailing List.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Emails can be sent using the send button at the bottom right.
                    Check the documentation for more information on sending emails,
                    and send records.
                </Typography>
            </DocletBody>
        </div>
    );
};

export const DocletSendDetail: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Email Send Detail"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/mail">Mail Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The Email Send Detail page shows detailed information about
                    an Email Send.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The summary panel shows the Email that was sent, the Mailing
                    List it was sent to, when it was (or will be) sent, and who sent
                    it, as well as the state of the send task.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The Delivery Stats panel shows the delivery status for each
                    address that the Email was sent to. Note that delivery stats are
                    best-effort and may not be entirely accurate.
                </Typography>
            </DocletBody>
        </div>
    );
};

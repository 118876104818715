import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';
import { TutorBuble } from '@acf-site/react-sdk/components/Common/Docs/TutorBuble';

import { SendEmail } from '@acf-site/client-sdk/lib/Mail/SendEmail/sagas/SendEmail';

import { SendBrowser } from '@acf-site/react-sdk/components/Mail/SendBrowser';

import { useStyles, useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';

import SendIcon from '@material-ui/icons/Send';

const PageSendBrowse: React.FunctionComponent = (): React.ReactElement => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    useTitle("Email Send Records");
    
    const onSendSelected = React.useCallback((sid: number) => {
        history.push(`/admin/mail/send/#${sid}`);
        
    }, [history]);
    
    const [page, setPage] = React.useState<number>(0);
    
    return (
        <React.Fragment>
            <SendBrowser
                limit={100}
                offset={page * 100}
                onSendSelected={onSendSelected}
                onPageTurn={setPage}
            />
            <div className={classes.fabContainer}>
                <Fab color="primary" aria-label="Send Email" title="Send Email" onClick={() => dispatch(SendEmail())}>
                    <SendIcon/>
                    <TutorBuble showKey="tutor-send-intro" showValue={0}/>
                </Fab>
            </div>
            <TutorBox showKey="tutor-send-intro" showValue={-1} closeValue={100} nextValue={0} prereqKey="tutor-intro" prereqValue={29}>
                <Typography variant="h5">Send Records</Typography>
                <Typography variant="body1">
                    Send Records, "Sends," or "Email Sends" represent a record
                    of or an intent to send an Email. Send Records are shown in
                    the Send Browser sorted by the send date/time, such that the
                    oldest is shown last.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-send-intro" showValue={0} closeValue={100} nextValue={1}>
                <Typography variant="h5">Send Email</Typography>
                <Typography variant="body1" gutterBottom>
                    The send button is used to send an Email to the recipients
                    in a Mailing List.
                </Typography>
                <Typography variant="body1">
                    Emails can either be sent immediately or scheduled to be
                    sent at some time/date in the future. Emails will be sent
                    within a few minutes of their scheduled send time.
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default withRouter(PageSendBrowse);

import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';

import { CreateEmailTemplate as CreateEmailTemplateDialog } from '@acf-site/react-sdk/components/Mail/EmailTemplate/CreateEmailTemplate';
import { DeleteEmailTemplate as DeleteEmailTemplateDialog } from '@acf-site/react-sdk/components/Mail/EmailTemplate/DeleteEmailTemplate';
import { CreateEmailTemplateDraft as CreateEmailTemplateDraftDialog } from '@acf-site/react-sdk/components/Mail/EmailTemplateDraft/CreateEmailTemplateDraft';
import { DeleteEmailTemplateDraft as DeleteEmailTemplateDraftDialog } from '@acf-site/react-sdk/components/Mail/EmailTemplateDraft/DeleteEmailTemplateDraft';
import { CreateEmail as CreateEmailDialog } from '@acf-site/react-sdk/components/Mail/Email/CreateEmail';
import { DeleteEmail as DeleteEmailDialog } from '@acf-site/react-sdk/components/Mail/Email/DeleteEmail';
import { CreateEmailDraft as CreateEmailDraftDialog } from '@acf-site/react-sdk/components/Mail/EmailDraft/CreateEmailDraft';
import { DeleteEmailDraft as DeleteEmailDraftDialog } from '@acf-site/react-sdk/components/Mail/EmailDraft/DeleteEmailDraft';
import { CreateMailingList as CreateMailingListDialog } from '@acf-site/react-sdk/components/Mail/MailingList/CreateMailingList';
import { DeleteMailingList as DeleteMailingListDialog } from '@acf-site/react-sdk/components/Mail/MailingList/DeleteMailingList';
import { SendTestEmail as TestSendDialog } from '@acf-site/react-sdk/components/Mail/SendTestEmail';
import { SendEmail as SendEmailDialog } from '@acf-site/react-sdk/components/Mail/SendEmail';
import { DeleteSend as DeleteSendDialog } from '@acf-site/react-sdk/components/Mail/Send/DeleteSend';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import EmailIcon from '@material-ui/icons/Mail';
import TemplatesIcon from '@material-ui/icons/MailOutline';
import MailingListIcon from '@material-ui/icons/ContactMail';
import SendIcon from '@material-ui/icons/Send';

import TemplateBrowserPage from './../../pages/admin/mail/templateBrowse';
import TemplateDetailPage from './../../pages/admin/mail/templateDetail';
import TemplateDraftDetailPage from './../../pages/admin/mail/templateDraftDetail';
import EmailBrowserPage from './../../pages/admin/mail/emailBrowse';
import EmailDetailPage from './../../pages/admin/mail/emailDetail';
import EmailDraftDetailPage from './../../pages/admin/mail/emailDraftDetail';
import MailingListBrowsePage from './../../pages/admin/mail/mailingListBrowse';
import MailingListDetailPage from './../../pages/admin/mail/mailingListDetail';
import SendBrowserPage from './../../pages/admin/mail/sendBrowse';
import SendDetailPage from './../../pages/admin/mail/sendDetail';
import MailPage from './../../pages/admin/mail/index';

const MailPageRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment>
            <CreateEmailTemplateDialog/>
            <DeleteEmailTemplateDialog/>
            <CreateEmailTemplateDraftDialog/>
            <DeleteEmailTemplateDraftDialog/>
            <CreateEmailDialog/>
            <DeleteEmailDialog/>
            <CreateEmailDraftDialog/>
            <DeleteEmailDraftDialog/>
            <CreateMailingListDialog/>
            <DeleteMailingListDialog/>
            <TestSendDialog/>
            <SendEmailDialog/>
            <DeleteSendDialog/>
            <Switch>
                <Route path="/admin/mail/templates/" render={() => <TemplateBrowserPage/>}/>
                <Route path="/admin/mail/template/" render={() => <TemplateDetailPage/>}/>
                <Route path="/admin/mail/templateDraft/" render={() => <TemplateDraftDetailPage/>}/>
                <Route path="/admin/mail/emails/" render={() => <EmailBrowserPage/>}/>
                <Route path="/admin/mail/email/" render={() => <EmailDetailPage/>}/>
                <Route path="/admin/mail/emailDraft/" render={() => <EmailDraftDetailPage/>}/>
                <Route path="/admin/mail/lists/" render={() => <MailingListBrowsePage/>}/>
                <Route path="/admin/mail/list/" render={() => <MailingListDetailPage/>}/>
                <Route path="/admin/mail/sends/" render={() => <SendBrowserPage/>}/>
                <Route path="/admin/mail/send/" render={() => <SendDetailPage/>}/>
                <Route path="/admin/mail/" exact render={() => <MailPage/>}/>
            </Switch>
        </React.Fragment>
    );
};

const MailAppBarRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <Switch>
            
        </Switch>
    );
};

const MailNavDrawerRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment>
            <Divider/>
            <List>
                <ListItem button component={Link} to={{ pathname: "/admin/mail/emails/", hash: "" }}>
                    <ListItemIcon><EmailIcon/></ListItemIcon>
                    <ListItemText>Emails</ListItemText>
                </ListItem>
                <ListItem button component={Link} to={{ pathname: "/admin/mail/templates/", hash: "" }}>
                    <ListItemIcon><TemplatesIcon/></ListItemIcon>
                    <ListItemText>Templates</ListItemText>
                </ListItem>
                <ListItem button component={Link} to={{ pathname: "/admin/mail/lists/", hash: "" }}>
                    <ListItemIcon><MailingListIcon/></ListItemIcon>
                    <ListItemText>Mailing Lists</ListItemText>
                </ListItem>
                <ListItem button component={Link} to={{ pathname: "/admin/mail/sends/", hash: "" }}>
                    <ListItemIcon><SendIcon/></ListItemIcon>
                    <ListItemText>Email Sending</ListItemText>
                </ListItem>
            </List>
        </React.Fragment>
    );
};

export {
    MailPageRoutes as PageRoutes,
    MailAppBarRoutes as AppBarRoutes,
    MailNavDrawerRoutes as NavDrawerRoutes
};

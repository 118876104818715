import React from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import { OrgPicker } from '@acf-site/react-sdk/components/Orgs/Utils/OrgPicker';
import { ProjectPicker } from '@acf-site/react-sdk/components/Orgs/Utils/ProjectPicker';
import { ProjectYearPicker } from '@acf-site/react-sdk/components/Orgs/Utils/ProjectYearPicker';
import { YearPicker } from '@acf-site/react-sdk/components/Orgs/Utils/YearPicker';

const PageTest: React.FunctionComponent = (): React.ReactElement => {
    const [org, setOrg] = React.useState<number>(-1);
    const [project, setProject] = React.useState<number>(-1);
    const [projectYear, setProjectYear] = React.useState<number>(-1);
    const [year, setYear] = React.useState<number>(-1);
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <OrgPicker
                    value={org}
                    variant="standard"
                    label="Organization"
                    id="org-picker-0"
                    name="picker-0"
                    onChange={setOrg}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <ProjectPicker
                    value={project}
                    variant="standard"
                    label="Project"
                    id="project-picker-0"
                    onChange={setProject}
                    fullWidth
                    oid={org >= 0 ? org : undefined}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <ProjectYearPicker
                    value={projectYear}
                    variant="standard"
                    label="Project Funding"
                    id="py-picker-0"
                    onChange={setProjectYear}
                    fullWidth
                    oid={org >= 0 ? org : undefined}
                    pid={project >= 0 ? project : undefined}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <YearPicker
                    value={year}
                    variant="filled"
                    label="some year"
                    id="year-picker-0"
                    fullWidth
                    oid={org}
                    pid={project}
                    onChange={setYear}
                />
            </Grid>
        </Grid>
    );
};

export default withRouter(PageTest);

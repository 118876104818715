import React from 'react';
import { Switch, Route, Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Root } from '@acf-site/client-sdk/lib/types';
import { User } from '@acf-site/core-sdk/lib/Auth/User';
import { selectUser } from '@acf-site/client-sdk/lib/Auth/User/reducer';
import { selectStateMe } from '@acf-site/client-sdk/lib/Auth/State/reducer';

import { TutorBuble } from '@acf-site/react-sdk/components/Common/Docs/TutorBuble';
import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import IconButton from '@material-ui/core/IconButton';
import MLink from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import PageIcon from '@material-ui/icons/MenuBook';
import FilesIcon from '@material-ui/icons/Folder';
import OrgIcon from '@material-ui/icons/Business';
import EmailIcon from '@material-ui/icons/Send';
import AccountIcon from '@material-ui/icons/AccountCircle';
import SignOutIcon from '@material-ui/icons/Lock';
import UsersIcon from '@material-ui/icons/People';
import UserIcon from '@material-ui/icons/Person';
import InboxIcon from '@material-ui/icons/Inbox';

import { SignOut } from '@acf-site/react-sdk/components/Auth/SignOut';
import Index, { AppBar as IndexAppBar, NavBar as IndexNavBar} from './../../pages/admin/index';

import { WsApiStatusIndicator } from '@acf-site/react-sdk/components/Common/WsApiStatusIndicator';

import * as AuthRoutes from './auth';
import * as CommonRoutes from './common';
import * as FilesRoutes from './files';
import * as MailRoutes from './mail';
import * as OrgsRoutes from './orgs';
import * as PagesRoutes from './pages';
import * as DocRoutes from './docs';

import TestPage from './../../pages/admin/test';

const PageRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={`/admin`} exact render={() => <Index/>}/>
                <Route path={`/admin/auth`} render={() => <AuthRoutes.PageRoutes/>}/>
                <Route path={`/admin/common`} render={() => <CommonRoutes.PageRoutes/>}/>
                <Route path={`/admin/docs`} render={() => <DocRoutes.PageRoutes/>}/>
                <Route path={`/admin/files`} render={() => <FilesRoutes.PageRoutes/>}/>
                <Route path={`/admin/pages`} render={() => <PagesRoutes.PageRoutes/>}/>
                <Route path={`/admin/orgs`} render={() => <OrgsRoutes.PageRoutes/>}/>
                <Route path={`/admin/mail`} render={() => <MailRoutes.PageRoutes/>}/>
                <Route path={`/admin/test`} render={() => <TestPage/>}/>
            </Switch>
            <Route path={[`/admin/*`]}>
                <TutorBox showKey="tutor-intro" showValue={20} closeValue={100} nextValue={21}>
                    <Typography variant="h5">Header</Typography>
                    <Typography variant="body1">
                        Most pages in the Admin Console follw a standard design pattern.
                        The page Header is a basic feature of al ost every page. On the
                        left of the header is an icon which identifies the basic
                        content type of the page. On the right of the icon is the
                        'breadcrumbs' of the page. The breadcrumbs can be used to
                        navigate back to another page.
                    </Typography>
                </TutorBox>
                <TutorBox showKey="tutor-intro" showValue={21} closeValue={100} nextValue={30}>
                    <Typography variant="h5">Refresh</Typography>
                    <Typography variant="body1">
                        Many pages  have a refresh button. The refresh button
                        does what you'd expect it to, it reloads the information
                        on the current page. The refresh button will also show a
                        loading spinner when page information is being loaded.
                    </Typography>
                </TutorBox>
            </Route>
            <Route path={[
                '/admin/pages/page', '/admin/pages/draft', '/admin/pages/template',
                '/admin/orgs/organization', '/admin/orgs/project',
                '/admin/mail/email', '/admin/mail/emailDraft', '/admin/mail/template', '/admin/mail/templateDraft',
                '/admin/mail/list', '/admin/mail/send',
                '/admin/common/task', '/admin/auth/user'
                ]}>
                <TutorBox showKey="tutor-intro" showValue={30} closeValue={100} nextValue={31}>
                    <Typography variant="h5">Delte / Discard</Typography>
                    <Typography variant="body1" gutterBottom>
                        The Delete button can be used to delete the current
                        resource. Many resources can be interdependent on one
                        another. As such, deleting a resource will often require
                        resolving these dependencies.
                    </Typography>
                    <Typography variant="body1">
                        Some pages have an "edit mode." When in edit mode, or if
                        changes have been made, the Delete button will become
                        the Discard button, which cancels any changes and exits
                        edit mode.
                    </Typography>
                </TutorBox>
                <TutorBox showKey="tutor-intro" showValue={31} closeValue={100} nextValue={32}>
                    <Typography variant="h5">Edit / Save</Typography>
                    <Typography variant="body1" gutterBottom>
                        The Edit button is used to enter edit mode. Edit mode
                        lets you edit certain properties of the current
                        resource.
                    </Typography>
                    <Typography variant="body1">
                        When in edit mode, or if changes have been made, the
                        Edit button becomes the Save button.
                    </Typography>
                </TutorBox>
                <TutorBox showKey="tutor-intro" showValue={32} closeValue={100} nextValue={33}>
                    <Typography variant="h5">Summary</Typography>
                    <Typography variant="body1">
                        Detail pages have a Summary panel, which shows the basic
                        properties of the current resource. While the specific
                        properties shown depend on the type of resource shown,
                        most will list the user who created the resource, the
                        last user to update the resource, and the date/time at
                        which the resource was created and updated.
                    </Typography>
                </TutorBox>
                <TutorBox showKey="tutor-intro" showValue={33} closeValue={100} nextValue={34}>
                    <Typography variant="h5">References</Typography>
                    <Typography variant="body1">
                        Most detail pages will also have a References panel.
                        The References panel lists other resources that the
                        current resource is linked to. This could include the
                        Organizations referenced in the body of a Page, or
                        Emails that contain links to a Page.
                    </Typography>
                </TutorBox>
                <TutorBox showKey="tutor-intro" showValue={34} closeValue={100} nextValue={40}>
                    <Typography variant="h5">Drafts</Typography>
                    <Typography variant="body1">
                        Page, Email, and Email Template detail pages include a
                        Drafts panel. The Drafts panel shows a list of drafts of
                        the current resource. The + icon at the top right of the
                        panel can be used to create a new draft. A star icon
                        indicates which draft was published most recently. The
                        pencil icon can be used to edit an existing draft.
                    </Typography>
                </TutorBox>
            </Route>
            <Route path={['/admin/pages/page', '/admin/mail/email']}>
                <TutorBox showKey="tutor-intro" showValue={40} closeValue={100} nextValue={50}>
                    <Typography variant="h5">Preview</Typography>
                    <Typography variant="body1">
                        The Page and Email detail pages have a Preview button.
                        For a Page the Preview button will open the Page in a
                        new tab. For an Email the Preview button lets the user
                        send the Email to a single recipient address. In either
                        case, the Page or Email must have been published in
                        order to be previewed.
                    </Typography>
                </TutorBox>
            </Route>
            <Route path={['/admin/pages/draft', '/admin/mail/emailDraft', '/admin/mail/templateDraft']}>
                <TutorBox showKey="tutor-intro" showValue={50} closeValue={100} nextValue={51}>
                    <Typography variant="h5">Editor</Typography>
                    <Typography variant="body1">
                        This is the Editor. The Editor is used to edit the
                        content of Page Drafts, Page Templates, Email Drafts,
                        and Email Templates. The usage of the editor is outside
                        of the scope of this tutorial and can be found in the
                        Documentation.
                    </Typography>
                </TutorBox>
                <TutorBox showKey="tutor-intro" showValue={51} closeValue={100} nextValue={52}>
                    <Typography variant="h5">Publish</Typography>
                    <Typography variant="body1" gutterBottom>
                        The Publish button is used to publish a draft as a new
                        version of its parent resource. For a Page Draft, this
                        results in the Page being rendered with the content of
                        the current draft. The new page content may not appear
                        to viewers for up to 24 hours. This is a side effect of
                        how page content is delivered to viewers.
                    </Typography>
                    <Typography variant="body1">
                        A Draft cannot be published if there are unsaved changes
                        to its content. If there are unsaved changes, then the
                        Publish button will become the Save button.
                    </Typography>
                </TutorBox>
            </Route>
            <Route path={['/admin/pages/draft', '/admin/mail/emailDraft', '/admin/mail/templateDraft']}>
                <TutorBox showKey="tutor-intro" showValue={52} closeValue={100}>
                    <Typography variant="h5">Preview</Typography>
                    <Typography variant="body1">
                        The Page and Email Draft editors have a Preview button.
                        Any changes made to the draft must be saved before they
                        will be visible in the preview.
                    </Typography>
                </TutorBox>
            </Route>
        </React.Fragment>
    );
}

const AdminAppBarItems: React.FunctionComponent = (): React.ReactElement => {
    const hist = useHistory();
    
    const authAnchor = React.useRef(null);
    const [authPop, setAuthPop] = React.useState<boolean>(false);
    
    const me: string | null = useSelector((root: Root) => selectStateMe(root));
    const user: User | null = useSelector((root: Root) => selectUser(root, me));
    
    return (
        <React.Fragment>
            <IconButton aria-label="Cloud Tasks" title="Cloud Tasks" color="inherit" onClick={() => hist.push("/admin/common/tasks/")}>
                <WsApiStatusIndicator/>
                <TutorBuble showKey="tutor-intro" showValue={7}/>
            </IconButton>
            <IconButton aria-label="Account Preferences" title="Account Preferences" color="inherit" onClick={React.useCallback(() => setAuthPop(true), [setAuthPop])} ref={authAnchor}>
                <AccountIcon/>
                <TutorBuble showKey="tutor-intro" showValue={8}/>
            </IconButton>
            <Menu
                id="app-bar-auth-menu"
                anchorEl={authAnchor.current}
                keepMounted
                open={authPop}
                onClose={React.useCallback(() => setAuthPop(false), [setAuthPop])}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                >
                <MenuItem onClick={React.useCallback(() => hist.push(`/admin/auth/user/#${me}`), [hist, me])} disabled={user === null}>
                    <ListItemIcon><UserIcon fontSize="small"/></ListItemIcon>
                    <ListItemText primary={(user === null) ? "" : user.name}/>
                </MenuItem>
                <MenuItem onClick={React.useCallback(() => hist.push("/admin/auth/users"), [hist])}>
                    <ListItemIcon><UsersIcon fontSize="small"/></ListItemIcon>
                    <ListItemText primary="Users"/>
                </MenuItem>
                <MLink href="https://andersonchildrensfoundation-org.awsapps.com/mail" target="_blank" color="textPrimary" underline="none">
                    <MenuItem>
                        <ListItemIcon><InboxIcon fontSize="small"/></ListItemIcon>
                        <ListItemText primary="Web Mail"/>
                    </MenuItem>
                </MLink>
                <SignOut>
                    <MenuItem>
                        <ListItemIcon><SignOutIcon fontSize="small"/></ListItemIcon>
                        <ListItemText primary="Sign Out"/>
                    </MenuItem>
                </SignOut>
            </Menu>
        </React.Fragment>
    );
};

const AppBarRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment>
            <Route path="/admin" exact render={() => <IndexAppBar/>}/>
            <Route path="/admin/auth" render={() => <AuthRoutes.AppBarRoutes/>}/>
            <Route path="/admin/common" render={() => <CommonRoutes.AppBarRoutes/>}/>
            <Route path="/admin/files" render={() => <FilesRoutes.AppBarRoutes/>}/>
            <Route path="/admin/pages" render={() => <PagesRoutes.AppBarRoutes/>}/>
            <Route path="/admin/orgs" render={() => <OrgsRoutes.AppBarRoutes/>}/>
            <Route path="/admin/mail" render={() => <MailRoutes.AppBarRoutes/>}/>
            <AdminAppBarItems/>
        </React.Fragment>
    );
};

const AdminNavDrawerItems: React.FunctionComponent = (): React.ReactElement => {
    return (
        <List>
            <ListItem button key="pages" component={Link} to={`/admin/pages`}>
                <ListItemIcon><PageIcon/>{/*<TutorBuble showKey="tutor-intro" showValue={2}/>*/}</ListItemIcon>
                <ListItemText>Pages</ListItemText>
            </ListItem>
            <ListItem button key="files" component={Link} to={`/admin/files`}>
                <ListItemIcon><FilesIcon/>{/*<TutorBuble showKey="tutor-intro" showValue={3}/>*/}</ListItemIcon>
                <ListItemText>Files</ListItemText>
            </ListItem>
            <ListItem button key="orgs" component={Link} to={`/admin/orgs`}>
                <ListItemIcon><OrgIcon/>{/*<TutorBuble showKey="tutor-intro" showValue={4}/>*/}</ListItemIcon>
                <ListItemText>Organizations</ListItemText>
            </ListItem>
            <ListItem button key="mail" component={Link} to={`/admin/mail`}>
                <ListItemIcon><EmailIcon/>{/*<TutorBuble showKey="tutor-intro" showValue={5}/>*/}</ListItemIcon>
                <ListItemText>Mail</ListItemText>
            </ListItem>
        </List>
    );
};

const NavDrawerRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment>
            <Route path="/admin" render={() => <AdminNavDrawerItems/>}/>
            <Route path="/admin" exact render={() => <IndexNavBar/>}/>
            <Route path="/admin/auth" render={() => <AuthRoutes.NavDrawerRoutes/>}/>
            <Route path="/admin/common" render={() => <CommonRoutes.NavDrawerRoutes/>}/>
            <Route path="/admin/files" render={() => <FilesRoutes.NavDrawerRoutes/>}/>
            <Route path="/admin/pages" render={() => <PagesRoutes.NavDrawerRoutes/>}/>
            <Route path="/admin/orgs" render={() => <OrgsRoutes.NavDrawerRoutes/>}/>
            <Route path="/admin/mail" render={() => <MailRoutes.NavDrawerRoutes/>}/>
        </React.Fragment>
    );
};

const DocletRoutes = DocRoutes.DocletRoutes;
export { PageRoutes, AppBarRoutes, NavDrawerRoutes, DocletRoutes };

import React from 'react';
import { useHistory } from 'react-router-dom';

import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import { FileExplorer } from '@acf-site/react-sdk/components/Files/FileExplorer';

import Typography from '@material-ui/core/Typography';

import FolderIcon from '@material-ui/icons/Folder';

const PageFilesBrowse: React.FunctionComponent = (): React.ReactElement => {
    const history = useHistory();
    React.useEffect(() => {
        document.title = `File Browser - ACF Admin`;
    });
    
    const path: string = history.location.hash;
    const dir: number = ((): number => {
        try {
            let p: number = parseInt(path.substring(1));
            if (Number.isInteger(p))
                return p;
            
            else
                return 0;
            
        } catch (e) {
            return 0;
        }
    })();
    
    return (
        <React.Fragment>
            <FileExplorer
                dir={dir}
                onNavigate={(dir: number) => history.push(`#${dir}`)}
                multiple
                />
            <TutorBox showKey="tutor-files-intro" showValue={-1} closeValue={100} nextValue={0} prereqKey="tutor-intro" prereqValue={29}>
                <Typography variant="h5">File Explorer</Typography>
                <Typography variant="body1">
                    This is the File Explorer. Here you can browse all of the
                    Files hosted for the ACF site. Any files, including images,
                    used in the user-facing site should be stored here.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-files-intro" showValue={0} closeValue={100} nextValue={1}>
                <Typography variant="h5"><FolderIcon/>&nbsp;Folders</Typography>
                <Typography variant="body1">
                    Like any file storage system, Files are organized in
                    Folders. Folders are always shows at the top of the File
                    Explorer, and can be identified by the folder icon. To view
                    the contents of a Foder, simply double-click the folder
                    you want to view.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-files-intro" showValue={1} closeValue={100} nextValue={2}>
                <Typography variant="h5">System Folders</Typography>
                <Typography variant="body1">
                    Some folders will have a colored icon. These are "system"
                    folders and cannot be altered directly. System folders are
                    usually wither folders that need to exist for some system
                    function, or are linked to another object. Common system
                    folders are Oragnization and Project Folders. Organization
                    and Project Folders cannot be deleted or moved unless
                    the correlating Project or Organization is deleted.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-files-intro" showValue={2} closeValue={100} nextValue={3}>
                <Typography variant="h5">Selecting Files</Typography>
                <Typography variant="body1">
                    You can select a file/folder simply by clicking on it. To
                    select multiple files, hold the Control key. To select all
                    of the files between two files, click the first file, hold
                    Shift, and click the last file you want selected.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-files-intro" showValue={3} closeValue={100} nextValue={4}>
                <Typography variant="h5">File Actions</Typography>
                <Typography variant="body1">
                    There are a number of different actions that can be
                    performed on selected file(s)/folder(s). These actions
                    vary depending on whether a file or folder is selected, and
                    if one or multiple files are selected. To perform an action
                    on a file or folder, select the file, and right click on it.
                    This will open a context-menu showing the different actions
                    that can be performed. Note that many actions appear in the
                    menu, but are not yet implemented.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-files-intro" showValue={4} closeValue={100} nextValue={5}>
                <Typography variant="h5">Create Folder</Typography>
                <Typography variant="body1">
                    The button in the bottom right is used to create a new
                    Folder. The Folder will be created in the current Folder.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-files-intro" showValue={5} closeValue={100} nextValue={6}>
                <Typography variant="h5">Upload</Typography>
                <Typography variant="body1">
                    The Upload button lets you upload files from your computer.
                    The uploaded Files will be placed in the current Folder.
                    The names of the Files may be changed if they include
                    certain characters that are not allowed in File names. The
                    system places restrictions on File/Folder names in order
                    to avoid potential problems with URLs.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-files-intro" showValue={6} closeValue={100} nextValue={7}>
                <Typography variant="h5">Pull</Typography>
                <Typography variant="body1">
                    The File Pull button lets you pull files from another
                    website. This is useful when you want to use a file from
                    another site, but don't need to download it to your computer
                    just to upload it again. Check the relevant Documentation
                    for more information.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-files-intro" showValue={7} closeValue={9}>
                <Typography variant="h5">Navigation Menu</Typography>
                <Typography variant="body1">
                    In the main navigation menu, you'll see two items that are
                    unique to the File Explorer. The first leads back to the
                    root of the file system. The second opens the Trash. Any
                    files that are deleted from the regular file system get
                    moved to the Trash. Files in the Trash can be restored to
                    the normal file system, or deleted permanently.
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default PageFilesBrowse;

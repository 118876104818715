import React from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import { LocationDescriptor } from 'history';

import { RedirectAuth } from '@acf-site/react-sdk/components/Auth/RedirectAuth';
import { RedirectNoAuth } from '@acf-site/react-sdk/components/Auth/RedirectNoAuth';

import AuthFrame from './../components/frames/AuthFrame';

import * as Auth from './auth';
import * as Admin from './admin';

const AppRoutes: React.FunctionComponent = (): React.ReactElement => {
    const location = useLocation<{onAuth?: LocationDescriptor}>();
    const redirAuth: LocationDescriptor = React.useMemo<LocationDescriptor>(() => ((location.state !== undefined && location.state.onAuth !== undefined) ? location.state.onAuth : { pathname: '/admin' }), [location]);
    //console.log(`redirAuth: ${JSON.stringify(redirAuth)}`);
    return (
        <Switch>
            <Route path="/auth" render={() => <RedirectAuth to={redirAuth}><PageRoutes/></RedirectAuth>}/>
            <Route path="/admin" render={() => <RedirectNoAuth to={{ pathname: "/auth" }}><AuthFrame>{{ pageRoutes: <PageRoutes/>, appBarRoutes: <AppBarRoutes/>, navDrawerRoutes: <NavDrawerRoutes/>, docletRoutes: <Admin.DocletRoutes/>}}</AuthFrame></RedirectNoAuth>}/>
            <Route path="/" exact render={() => <Redirect to="/auth"/>}/>
        </Switch>
    );
};

const PageRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment>
            <Route path="/auth" render={() => <Auth.PageRoutes/>}/>
            <Route path="/admin" render={() => <Admin.PageRoutes/>}/>
        </React.Fragment>
    );
};

const AppBarRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment>
            <Route path="/admin" render={() => <Admin.AppBarRoutes/>}/>
        </React.Fragment>
    );
};

const NavDrawerRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment>
            <Route path="/admin" render={() => <Admin.NavDrawerRoutes/>}/>
        </React.Fragment>
    );
};

const FooterRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment></React.Fragment>
    );
};

export { AppRoutes as default, PageRoutes, AppBarRoutes, NavDrawerRoutes, FooterRoutes };

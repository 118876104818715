import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useIntHash, useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { Root } from '@acf-site/client-sdk/lib/types';

import { Draft } from '@acf-site/core-sdk/lib/Pages/Draft';
import { selectDraft } from '@acf-site/client-sdk/lib/Pages/Draft/reducer';

import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import { DraftDetail } from '@acf-site/react-sdk/components/Pages/Ui/DraftDetail';

import Typography from '@material-ui/core/Typography';

const PageDraftDetail: React.FunctionComponent = (): React.ReactElement => {
    const did: number = useIntHash();
    const draft: Draft | null = useSelector((state: Root) => selectDraft(state, did));
    useTitle(draft === null ? `Draft ${did}` : draft.name);
    
    return (
        <React.Fragment>
            <DraftDetail did={did}/>
            <TutorBox showKey="tutor-page-draft" showValue={-1} closeValue={100} nextValue={0} prereqKey="tutor-intro" prereqValue={59}>
                <Typography variant="h5">Draft Editor</Typography>
                <Typography variant="body1">
                    This is the Draft Editor. The Draft Editor is used to edit
                    Pages, Page Templates, Emails, and Email Templates.
                </Typography>
                <Typography variant="body1">
                    This tutorial does not go into detail on how to use the
                    editor. For a detailed explanation of how to use the Draft 
                    Editor, check the relevant documentation.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-page-draft" showValue={0} closeValue={1}>
                <Typography variant="h5">Template</Typography>
                <Typography variant="body1">
                    The Template selector allows you to choose a Page Template
                    to use when rendering the Draft, or the Page when the Draft
                    is Published.
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default withRouter(PageDraftDetail);

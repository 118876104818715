import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import { useIntHash, useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { Root } from '@acf-site/client-sdk/lib/types';

import { MailingList } from '@acf-site/core-sdk/lib/Mail/MailingList';
import { selectMailingList } from '@acf-site/client-sdk/lib/Mail/MailingList/reducer';

import { MailingListDetail } from '@acf-site/react-sdk/components/Mail/MailingListDetail';

import Typography from '@material-ui/core/Typography';

const PageMailingListDetail: React.FunctionComponent = (): React.ReactElement => {
    const mlid: number = useIntHash();
    const mailingList: MailingList | null = useSelector((state: Root) => selectMailingList(state, mlid));
    useTitle(mailingList === null ? `Mailing List ${mlid}` : mailingList.name);
    
    return (
        <React.Fragment>
            <MailingListDetail mlid={mlid}/>
            <TutorBox showKey="tutor-mailing-list-detail" showValue={-1} closeValue={100} nextValue={0} prereqKey="tutor-intro" prereqValue={39}>
                <Typography variant="h5">Mailing List</Typography>
                <Typography variant="body1">
                    This is the Mailing List detail page. Here, you can view the
                    recipients in a Mailing List, and edit the items on the
                    list.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-mailing-list-detail" showValue={0} closeValue={100} nextValue={1}>
                <Typography variant="h5">Addresses</Typography>
                <Typography variant="body1">
                    The addresses panel shows the contents of the Mailing List.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-mailing-list-detail" showValue={1} closeValue={2}>
                <Typography variant="h5">Editing</Typography>
                <Typography variant="body1" gutterBottom>
                    In edit mode, a new panel opens to add new addresses.
                    Addresses can be added one at a time, or in bulk as a space-
                    or comma-separated list.
                </Typography>
                <Typography variant="body1">
                    In edit mode, the @ icon in the address lise becomes a
                    checkbox, which is used to select entries to be removed from
                    the Mailing List.
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default withRouter(PageMailingListDetail);

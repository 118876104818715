import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { DocletHeading } from '@acf-site/react-sdk/components/Common/Docs/DocletHeading';
import { DocletBody } from '@acf-site/react-sdk/components/Common/Docs/DocletBody';

import { useStyles } from './index';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export const DocFiles: React.FunctionComponent = (): React.ReactElement => {
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
                    <Link component={RouterLink} to="/admin/docs/">Docs</Link>
                    <Typography color="textPrimary">Files</Typography>
                </Breadcrumbs>
                <Typography variant="h4">Files</Typography>
                <Typography variant="body1" gutterBottom>
                    The File subsystem  si used to manage files that are used
                    in the user-facing site, or in Emails.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Contents:</Typography>
                <ul>
                    <li><Link component={RouterLink} to="#fids">File IDs</Link></li>
                    <li><Link component={RouterLink} to="#folders">Folders</Link></li>
                    <li><Link component={RouterLink} to="#images">Images</Link></li>
                    <li><Link component={RouterLink} to="#uploading">Uploading Files</Link></li>
                    <li><Link component={RouterLink} to="#pulling">Pulling Files</Link></li>
                    <li><Link component={RouterLink} to="#trash">Trash</Link></li>
                </ul>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
                <Typography variant="h5" id="fids">File IDs</Typography>
                <Typography variant="body1" gutterBottom>
                    The File subsystem assigns every file a unique ID number.
                    All other systems refer to Files by their ID number. This
                    allows Files to be renamed or moved without other systems
                    needing to do anything. Renaming or moving a File does not
                    result in Pages referencing the File being rendered.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
                <Typography variant="h5" id="folders">Folders</Typography>
                <Typography variant="body1" gutterBottom>
                    The file system uses folders to help keep Files organized.
                    However, the hierarchical organization of Files in folders
                    is an illusion. The Files are actually stored in a single,
                    flat folder under their ID number. The combination of File
                    IDs and the "virtual" folder tree allows Files to be moved
                    or renamed without impacting other systems.
                </Typography>
                <Typography variant="subtitle1">System Folders</Typography>
                <Typography variant="body1" gutterBottom>
                    System Folders are special folders that are required to
                    exist by some other system. Because they are dependencies
                    of other systems, system folders cannot be directly modified
                    by the user. System folders are usually identified by having
                    a colored icon in the File Explorer. Examples of system
                    folders include the Organizations folder, where Organizaiton
                    root folders are kept. The Organization root folders are
                    also system folders, because they are linked to an
                    Organization. Similarly, the Project folders in each
                    Organization folder are system folders.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
                <Typography variant="h5" id="images">Images</Typography>
                <Typography variant="body1" gutterBottom>
                    The file subsystem has special handling for image files.
                    When an image file is used in a Page, the HTML is rendered
                    in such a way that the user's browser requests the image
                    in a specific size. By sending smaller images to devices
                    with smaller screens, the page can load faster and consume
                    fewer resources on the user's device.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
                <Typography variant="h5" id="uploading">Uploading Files</Typography>
                <Typography variant="body1" gutterBottom>
                    Files can be uploaded using the Upload button in the File
                    Explorer. Pressing the upload button opens the upload
                    dialog. Select files to upload by pressing the "Select
                    Files" button. When a file is selected to upload, it is
                    added to a list in the upload dialog. The File's name is
                    automatically changed to adhere to the requirements for file
                    names. File names cannot contain spaces, and can only
                    contain a select set of symbols. File type extensions (.doc,
                    .zip, .pdf, etc.) are allowable but not required. To cancel
                    uploading a file, use the trash-can icon on the right of
                    the file. Press the "Upload" button to upload the selected
                    files.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Uploaded files will appear in the folder where the upload
                    button was pressed. While the upload dialog may dissappear
                    immediately on pressing "Upload," the upload is still being
                    processed in the background. Thus it is important that you
                    do not close or refresh the page unless you are sure that
                    all uploads are complete. You can navigate to other pages
                    in the Admin Console, as long as you don't refresh the page.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
                <Typography variant="h5" id="pulling">Pulling Files</Typography>
                <Typography variant="body1" gutterBottom>
                    File Pulling is on e of the more unique features of the
                    Admin Console. File pulling allows the user to take files
                    from another website and add them to the file system without
                    downloading them first.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    To pull files, press the file pull button in the File
                    Explorer. The file pull dialog has two fields, the URL of
                    the file to pull, and the name to give the pulled file. To
                    find the URL of an image, right-click the image (ctrl+click 
                    on Mac) and choose Copy Image Address. Press the Add button
                    to add the URL to the list of files to pull. When you have
                    entered all of the URLs you want to pull, press Pull Files.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Unlike file uploads, file pulls are processed entirely on
                    the server. Thus you are free to refresh or close the Admin
                    Console without disrupting the file pulling process.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
                <Typography variant="h5" id="trash">Trash</Typography>
                <Typography variant="body1" gutterBottom>
                    Files that are deleted from the regular file system are
                    moved to the Trash. Files in the Trash are not available to
                    be used in Pages or Emails. Files in the trash may still be
                    accessible to viewers for up to 24 hours, if they obtained
                    the public URL of the file before it was moved to the trash.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The trash can be accessed in the File Explorer using the
                    trash link in the main navigation menu. Files in the trash
                    can be deleted permanently or restored to their previous
                    location in the main file system.
                </Typography>
            </Grid>
        </Grid>
    );
};

export const DocletFileExplorer: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="File Explorer"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/files">Files Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The File Explorer is used to navigate the file system, where
                    files used in Pages and Emails are stored.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    To open a folder, double click on the folder you want to open.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Create a folders, upload files, and pull files from other sites
                    using the buttons at the bottom right.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Use ctrl+click to select multiple files/folders, or shift+click
                    to select a range of files/folders.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Right click (ctrl+click on Mac) on a file to open the context
                    menu, where you can perform various actions on the file(s).
                </Typography>
            </DocletBody>
        </div>
    );
};

import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import { useIntHash, useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { Root } from '@acf-site/client-sdk/lib/types';

import { Organization } from '@acf-site/core-sdk/lib/Orgs/Organization';
import { selectOrganization } from '@acf-site/client-sdk/lib/Orgs/Organization/reducer';

import { OrgDetail } from '@acf-site/react-sdk/components/Orgs/OrgDetail';

import Typography from '@material-ui/core/Typography';

const PageOrgDetail: React.FunctionComponent = (): React.ReactElement => {
    const oid: number = useIntHash();
    const org: Organization | null = useSelector((state: Root) => selectOrganization(state, oid));
    useTitle(org === null ? `Organization ${oid}` : org.name);
    
    return (
        <React.Fragment>
            <OrgDetail oid={oid}/>
            <TutorBox showKey="tutor-org-detail" showValue={-1} closeValue={100} nextValue={0} prereqKey="tutor-intro" prereqValue={39}>
                <Typography variant="h5">Organization Detail</Typography>
                <Typography variant="body1">
                    This is the Organization Detail page. The Organizaiton
                    Detail page allows management of a few Organization
                    parameters, but is mostly used to navigate to other
                    resources related to an Organization.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-org-detail" showValue={0} closeValue={100} nextValue={1}>
                <Typography variant="h5">Links</Typography>
                <Typography variant="body1">
                    The Links panel shows links to the Organization's home Page
                    and root directory (folder). Any public information about
                    the Organization (including descriptions of Projects) should
                    be written in the linked home Page. Similarly, Organization
                    related files, such as Project images should be stored in
                    the linked root Folder.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-org-detail" showValue={1} closeValue={100} nextValue={2}>
                <Typography variant="h5">Projects</Typography>
                <Typography variant="body1">
                    The Projects panel shows a list of the Organization's 
                    Projects that have been funded by the ACF.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-org-detail" showValue={2} closeValue={3}>
                <Typography variant="h5">Create Project</Typography>
                <Typography variant="body1">
                    The + button isused to create new Projects for the
                    Organization. Check the relevant Documentation for details
                    on creating Projects.
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default withRouter(PageOrgDetail);

import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { DocletHeading } from '@acf-site/react-sdk/components/Common/Docs/DocletHeading';
import { DocletBody } from '@acf-site/react-sdk/components/Common/Docs/DocletBody';

import { useStyles } from './index';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export const DocUsers: React.FunctionComponent = (): React.ReactElement => {
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
                    <Link component={RouterLink} to="/admin/docs/">Docs</Link>
                    <Typography color="textPrimary">Users</Typography>
                </Breadcrumbs>
                <Typography variant="h4">Users</Typography>
                <Typography variant="body1" gutterBottom>
                    Users are users. ACF Admin Console users. Users only have a
                    few properties, their first and last name, their ACF email
                    address, and a non-acf email address. Some users have
                    enhanced priviliges. These "super" users can manage other
                    users.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Contents:</Typography>
                <ul>
                    <li><Link component={RouterLink} to="#creating">Creating Users</Link></li>
                    <li><Link component={RouterLink} to="#disabling">Disabling / Enabling Users</Link></li>
                    <li><Link component={RouterLink} to="#deleting">Deleting Users</Link></li>
                </ul>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
                <Typography variant="h5" id="creating">Creating Users</Typography>
                <Typography variant="body1" gutterBottom>
                    Only Super Users can create new Users. New Users are created
                    using the Create User dialog, accessed via the create user
                    button at the bottom right of the User Browser page.
                    The Create User dialog has 4 fields, the first and last name
                    of the User, the ACF email address for the user (defaults to
                    first name), and the email address to send the invitation
                    to.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    When a User is created, an Admin Console and ACF Web Mail
                    account are created for them. Information on signing into
                    the Admin Console account is sent to the ACF Web Mail inbox.
                    Information on signing into the Web Mail account is sent to
                    the provided invite email address.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
                <Typography variant="h5" id="disabling">Disabling / Enabling Users</Typography>
                <Typography variant="body1" gutterBottom>
                    Only Super Users are able to manage other Users. Users can 
                    be disabled or enabled using the disable/enable button on
                    the User Detail page. When a User is disabled, they can no
                    longer access the Admin Console, and their ACF email account
                    is disabled. Disabling a User requires selecting another
                    User who will receive any emails sent to the disabled User.
                    If the User is left in a disabled state for 30 days, then
                    the User's inbox will be deleted.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
                <Typography variant="h5" id="deleting">Deleting Users</Typography>
                <Typography variant="body1" gutterBottom>
                    Only Super Users are able to manage other Users. When a User
                    is disabled, that User can be Deleted. When a User is
                    deleted, their Admin Console and ACF Mail accounts are
                    permanently deleted, and cannot be restored.
                </Typography>
            </Grid>
        </Grid>
    );
};

export const DocletUserBrowser: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="User Browser"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/users">User Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The User Browser shows a list of Admin Console users.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Users with adequate priviliges will have a button at the bottom
                    right to create new users.
                </Typography>
            </DocletBody>
        </div>
    );
};

export const DocletUserDetail: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="User Detail"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/users">User Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The User Detail page shows details of a User.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Users with adequate priviliges will be able to perform certain
                    actions on Users.
                </Typography>
            </DocletBody>
        </div>
    );
};

import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { DocletHeading } from '@acf-site/react-sdk/components/Common/Docs/DocletHeading';
import { DocletBody } from '@acf-site/react-sdk/components/Common/Docs/DocletBody';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        breadcrumbs: {
            margin: theme.spacing(1, 0)
        },
        divider: {
            margin: theme.spacing(0, 0, 2, 0)
        },
        subheading: {
            margin: theme.spacing(3, 0, 0.5, 0)
        },
        inlineIcon: {
            verticalAlign: 'text-bottom'
        }
    })
);

export const DocIndex: React.FunctionComponent = (): React.ReactElement => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h4">Documentation</Typography>
                <Typography variant="body1" gutterBottom>
                    Welcome to the built-in system documentation. Here you can
                    find information about the various subsystems and interfaces
                    of the ACF Admin Console.
                </Typography>
                <Typography variant="h5">Interface</Typography>
                <ul>
                    <li><Link component={RouterLink} to="/admin/docs/appbar">App Bar</Link></li>
                    <li><Link component={RouterLink} to="/admin/docs/editor">Editor</Link></li>
                </ul>
                <Typography variant="h5">Systems</Typography>
                <ul>
                    <li><Link component={RouterLink} to="/admin/docs/pages">Pages</Link></li>
                    <li><Link component={RouterLink} to="/admin/docs/files">Files</Link></li>
                    <li><Link component={RouterLink} to="/admin/docs/mail">Mail</Link></li>
                    <li><Link component={RouterLink} to="/admin/docs/users">User</Link></li>
                </ul>
            </Grid>
        </Grid>
    );
};

export const DocletIndex: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Dashboard"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/">Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    This is the ACF Admin Console Dashboard. It's empty right now,
                    but something will be added here later.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Check the documentation drawer on other pages for more helpful
                    content.
                </Typography>
            </DocletBody>
        </div>
    );
};

export const DocletDocs: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Documentation"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom>
                    This is the built-in system documentation.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Close the documentation drawer to read the full system
                    documentation.
                </Typography>
            </DocletBody>
        </div>
    );
};

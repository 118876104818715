import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import { Email } from '@acf-site/core-sdk/lib/Mail/Email';

import { useIntHash, useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { Root } from '@acf-site/client-sdk/lib/types';
import { selectEmail } from '@acf-site/client-sdk/lib/Mail/Email/reducer';

import { EmailDetail } from '@acf-site/react-sdk/components/Mail/EmailDetail';

import Typography from '@material-ui/core/Typography';

const PageEmailDetail: React.FunctionComponent = (): React.ReactElement => {
    const eid: number = useIntHash();
    const email: Email | null = useSelector((state: Root) => selectEmail(state, eid));
    useTitle(email === null ? `Email ${eid}` : email.name);
    
    return (
        <React.Fragment>
            <EmailDetail eid={eid}/>
            <TutorBox showKey="tutor-email-detail" showValue={-1} closeValue={100} nextValue={0} prereqKey="tutor-intro" prereqValue={49}>
                <Typography variant="h5">Email Detail</Typography>
                <Typography variant="body1">
                    This is the Email Detail page. It shows an overview of the
                    Email and related resources.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-email-detail" showValue={0} closeValue={1}>
                <Typography variant="h5">Send Records</Typography>
                <Typography variant="body1">
                    The Send Records panel shows a list of Email Send Records
                    for the current Email. Send Records are represented as the
                    Mailing List that the Email was or will be sent to, and the
                    date at which it was or will be sent.
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default withRouter(PageEmailDetail);

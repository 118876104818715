import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { TutorBuble } from '@acf-site/react-sdk/components/Common/Docs/TutorBuble';
import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import { useStyles, useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { CreateEmailTemplate } from '@acf-site/client-sdk/lib/Mail/CreateEmailTemplate/sagas/CreateEmailTemplate';

import { EmailTemplateBrowser } from '@acf-site/react-sdk/components/Mail/EmailTemplateBrowser';

import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';

const PageEmailTemplatesBrowse: React.FunctionComponent = (): React.ReactElement => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    useTitle("Email Templates");
    
    const onEmailTemplateSelected = React.useCallback((etid: number) => {
        history.push(`/admin/mail/template/#${etid}`);
        
    }, [history]);
    
    const [page, setPage] = React.useState<number>(0);
    
    return (
        <React.Fragment>
            <EmailTemplateBrowser
                limit={100}
                offset={page * 100}
                onEmailTemplateSelected={onEmailTemplateSelected}
                onPageTurn={setPage}
            />
            <div className={classes.fabContainer}>
                <Fab color="primary" aria-label="Create Email Template" title="Create Email Template" onClick={() => dispatch(CreateEmailTemplate(true))}>
                    <AddIcon/>
                    <TutorBuble showKey="tutor-email-template-intro" showValue={0}/>
                </Fab>
            </div>
            <TutorBox showKey="tutor-email-template-intro" showValue={-1} closeValue={100} nextValue={0} prereqKey="tutor-intro" prereqValue={29}>
                <Typography variant="h5">Email Template</Typography>
                <Typography variant="body1">
                    This is the Email Template Browser. Email Templates are used
                    to make sure that many Emails have similar content or
                    layout without having to manually maintain that layout in
                    each Email.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-email-template-intro" showValue={0} closeValue={1}>
                <Typography variant="h5">Create Email Template</Typography>
                <Typography variant="body1">
                    The + button can be used to create a new Email Template.
                    Check the relevant Documentation to learn more about Email
                    Templates and creating them.
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default withRouter(PageEmailTemplatesBrowse);

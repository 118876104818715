import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import { useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { RenderPages } from '@acf-site/client-sdk/lib/Pages/RenderPages/sagas/RenderPages';
import { SendEmail } from '@acf-site/client-sdk/lib/Mail/SendEmail/sagas/SendEmail';

import { Header } from '@acf-site/react-sdk/components/Common/Page/Header';
import { Heading } from '@acf-site/react-sdk/components/Common/Page/Heading';
import { HeaderButton } from '@acf-site/react-sdk/components/Common/Page/HeaderButton';

import { TaskBrowser } from '@acf-site/react-sdk/components/Common/TaskBrowser';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import RefreshIcon from '@material-ui/icons/Refresh';
import RenderIcon from '@material-ui/icons/Description'
import SendIcon from '@material-ui/icons/Send';
import TaskIcon from '@material-ui/icons/Assignment';

const PageTaskBrowse: React.FunctionComponent = (): React.ReactElement => {
    const history = useHistory();
    const dispatch = useDispatch();
    useTitle("Tasks");
    
    const sRefresh = React.useRef(() => {});
    const rRefresh = React.useRef(() => {});
    
    const onTaskSelected = React.useCallback((tid: number) => {
        history.push(`/admin/common/task/#${tid}`);
        
    }, [history]);
    
    const [sPage, setSPage] = React.useState<number>(0);
    const [rPage, setRPage] = React.useState<number>(0);
    
    const onRefresh = React.useCallback(() => {
        sRefresh.current();
        rRefresh.current();
        
    }, [sRefresh, rRefresh]);
    
    const onRenderPages = React.useCallback(() => {
        dispatch(RenderPages(true));
        
    }, [dispatch]);
    const onSendEmail = React.useCallback(() => {
        dispatch(SendEmail(undefined, undefined, true));
        
    }, [dispatch]);
    
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Header component={Grid} rootProps={{ item: true, xs: 12 }}>
                    <Heading
                        primaryText="Tasks"
                        icon={TaskIcon}
                        iconTitle="Tasks"
                        highlightKey="tutor-intro"
                        highlightValue={20}
                    />
                    <HeaderButton
                        variant={0}
                        color="secondary"
                        variants={[
                            { id: 0, tooltip: "Render Pages", icon: RenderIcon, onClick: onRenderPages }
                        ]}
                        highlightKey="tutor-tasks-intro"
                        highlightValue={1}
                    />
                    <HeaderButton
                        variant={0}
                        color="secondary"
                        variants={[
                            { id: 0, tooltip: "Send Email", icon: SendIcon, onClick: onSendEmail }
                        ]}
                        highlightKey="tutor-tasks-intro"
                        highlightValue={1}
                    />
                    <HeaderButton
                        variant={0}
                        color="primary"
                        variants={[
                            { id: 0, tooltip: "Refresh", icon: RefreshIcon, onClick: onRefresh }
                        ]}
                        highlightKey="tutor-intro"
                        highlightValue={21}
                    />
                </Header>
                <Grid item xs={12}>
                    <Typography variant="h6">Email Send Tasks</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TaskBrowser
                        taskType={1}
                        label="Email Send"
                        limit={10}
                        offset={sPage * 10}
                        onTaskSelected={onTaskSelected}
                        onPageTurn={setSPage}
                        doRefresh={sRefresh}
                        header={false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Page Render Tasks</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TaskBrowser
                        taskType={0}
                        label="Page Render"
                        limit={10}
                        offset={rPage * 10}
                        onTaskSelected={onTaskSelected}
                        onPageTurn={setRPage}
                        doRefresh={rRefresh}
                        header={false}
                    />
                </Grid>
            </Grid>
            <TutorBox showKey="tutor-tasks-intro" showValue={-1} closeValue={100} nextValue={0} prereqKey="tutor-intro" prereqValue={29}>
                <Typography variant="h5">Tasks</Typography>
                <Typography variant="body1">
                    Tasks are units of work that run in the background. As
                    background processes, they are used to perform large amounts
                    of work that takes a long time to complete.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-tasks-intro" showValue={0} closeValue={100} nextValue={1}>
                <Typography variant="h5">Page Render Tasks</Typography>
                <Typography variant="body1" gutterBottom>
                    Page Render Tasks are used when a large number of Pages need
                    to be rendered. Normally, if a small number of Pages need to
                    be rendered, they are added to a queue and rendered as
                    quickly as possible. When a lot of Pages need to be rendered
                    at once, a Render Task is started. 
                </Typography>
                <Typography variant="body1">
                    Page Render Tasks can be started manually using the
                    highlighted button. For details on the different parameters
                    for starting a Render Task, check the relevant
                    documentation.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-tasks-intro" showValue={1} closeValue={2}>
                <Typography variant="h5">Send Tasks</Typography>
                <Typography variant="body1" gutterBottom>
                    Send Tasks are created to process Email Sends. An Email can
                    only be sent to a few addresses at a time, so a Task is
                    needed to send an Email to all of the addresses on a Mailing
                    List.
                </Typography>
                <Typography variant="body1">
                    The Send button can be used to create a new Email Send,
                    which results in the creation of a new Send Task.
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default withRouter(PageTaskBrowse);

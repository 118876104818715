import React from 'react';
import { Switch, Route, Link, Redirect } from 'react-router-dom';

import { CreatePage as CreatePageDialog } from '@acf-site/react-sdk/components/Pages/Ui/CreatePage';
import { DeletePage as DeletePageDialog } from '@acf-site/react-sdk/components/Pages/Ui/DeletePage';
import { CreateDraft as CreateDraftDialog } from '@acf-site/react-sdk/components/Pages/Ui/CreateDraft';
import { DeleteDraft as DeleteDraftDialog } from '@acf-site/react-sdk/components/Pages/Ui/DeleteDraft';
import { CreateTemplate as CreateTemplateDialog } from '@acf-site/react-sdk/components/Pages/Ui/CreateTemplate';
import { DeleteTemplate as DeleteTemplateDialog } from '@acf-site/react-sdk/components/Pages/Ui/DeleteTemplate';
import { RenderPages as RenderPagesDialog } from '@acf-site/react-sdk/components/Pages/Ui/RenderPages';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import BrowseIcon from '@material-ui/icons/DeviceHub';
import TemplatesIcon from '@material-ui/icons/FileCopy';

import BrowsePage from './../../pages/admin/pages/browse';
import DetailPage from './../../pages/admin/pages/detail';
import DraftDetailPage from './../../pages/admin/pages/draft';
import TemplateBrowsePage from './../../pages/admin/pages/templates';
import TemplateDetailPage from './../../pages/admin/pages/templateDetail';

const PagesPageRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment>
            <CreatePageDialog/>
            <DeletePageDialog/>
            <CreateDraftDialog/>
            <DeleteDraftDialog/>
            <CreateTemplateDialog/>
            <DeleteTemplateDialog/>
            <RenderPagesDialog/>
            <Switch>
                <Route path="/admin/pages/browse" render={() => <BrowsePage/>}/>
                <Route path="/admin/pages/page/" render={() => <DetailPage/>}/>
                <Route path="/admin/pages/draft/" render={() => <DraftDetailPage/>}/>
                <Route path="/admin/pages/templates/" render={() => <TemplateBrowsePage/>}/>
                <Route path="/admin/pages/template/" render={() => <TemplateDetailPage/>}/>
                <Redirect path="/admin/pages" exact to="/admin/pages/browse"/>
            </Switch>
        </React.Fragment>
    );
};

const PagesAppBarRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <Switch>
            
        </Switch>
    );
};

const PagesNavDrawerRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment>
            <Divider/>
            <List>
                <ListItem button key="root" component={Link} to={{ pathname: "/admin/pages/browse/", hash: "" }}>
                    <ListItemIcon><BrowseIcon/></ListItemIcon>
                    <ListItemText>Browse</ListItemText>
                </ListItem>
                <ListItem button key="templates" component={Link} to={{ pathname: "/admin/pages/templates/", hash: "" }}>
                    <ListItemIcon><TemplatesIcon/></ListItemIcon>
                    <ListItemText>Page Templates</ListItemText>
                </ListItem>
            </List>
        </React.Fragment>
    );
};

export { PagesPageRoutes as PageRoutes, PagesAppBarRoutes as AppBarRoutes, PagesNavDrawerRoutes as NavDrawerRoutes };

import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { DocletHeading } from '@acf-site/react-sdk/components/Common/Docs/DocletHeading';
import { DocletBody } from '@acf-site/react-sdk/components/Common/Docs/DocletBody';

import { useStyles } from './index';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export const DocTasks: React.FunctionComponent = (): React.ReactElement => {
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
                    <Link component={RouterLink} to="/admin/docs/">Docs</Link>
                    <Typography color="textPrimary">Tasks</Typography>
                </Breadcrumbs>
                <Typography variant="h4">Tasks</Typography>
                <Typography variant="body1" gutterBottom>
                    Tasks represent long-running workloads that run in the
                    background. Workloads that either need to run autonomously
                    (ie. on a schedule), or that may take a very long time to
                    run are offloaded to Tasks. Tasks, in the context of the
                    Admin Console, refer to records of the background process's
                    state. There are different types of Tasks, which represent
                    different workloads. Note that the work "task" may be used
                    in this documentation to refer to both the Task record, and
                    the actual background process represented by the Task.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Contents:</Typography>
                <ul>
                    <li><Link component={RouterLink} to="#types-render">Page Render Tasks</Link></li>
                    <li><Link component={RouterLink} to="#types-send">Email Send Tasks</Link></li>
                </ul>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
                <Typography variant="h5" id="types-render">Page Render Tasks</Typography>
                <Typography variant="body1" gutterBottom>
                    Page Render Tasks are created when a large number of Pages
                    need to be rendered at once. For example, if a Page Template
                    that is used by many Pages is updated, then a Page Render
                    Task may be created to render the affected Pages. However,
                    if a single Page is published, then a Task is not used,
                    because only a single Page needs to be rendered.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    A Page Render Task can render 200 Pages in about two
                    minutes. Without using a task, rendering a single Page ten
                    to twenty seconds. However, Tasks have large start-up costs.
                    In the time it takes a Page Render Task to start rendering
                    Pages, a single Page would already be rendered using the
                    non-task based Page renderer.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    When a Page needs to be rendered, but a task is not
                    necessary, the Page is set to the Queued state, and added to
                    the Page Render Queue. Pages in the queue are processed one
                    by one. The queue-based renderer starts up much faster than
                    a task, but becomes less efficient when many Pages need to
                    be rendered, thus the use of render tasks.
                </Typography>
                <Typography variant="h6" className={classes.subheading}>Creating Page Render Tasks</Typography>
                <Typography variant="body1" gutterBottom>
                    Page Render Tasks are usually started automatically.
                    Anything that can trigger multiple Page renders (renaming
                    a Page, updating a Page Template, modifying an Organization,
                    etc.) will automatically create a Page Render Task if it
                    will cause more than 25 Pages to be rendered.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Page Render Tasks can be started manually, using the Render
                    Pages button in the Task Browser. The Render Pages dialog,
                    contains two fields. The first is a list of Page States.
                    Pages in the selected states will be rendered by the created
                    task. The second is whether to purge the render queue. If
                    you are rendering queued Pages, it is best to purge the
                    render queue, as this will prevent the queue basesd renderer
                    from rendering the same Pages as the render task.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
                <Typography variant="h5" id="types-send">Email Send Tasks</Typography>
                <Typography variant="body1" gutterBottom>
                    Email Send Tasks are used to send emails. The system used to
                    send emails (Amazon SES) limits the number of emails send at
                    once, per second, and per hour. Thus it is necessary to
                    break the recipients on a Mailing List into smaller batches,
                    to make sure the SES limits are not exceeded. The result is
                    that sending an Email to the recipients on a Mailing List
                    can take a long (relatively) time. Email Sends can also be
                    scheduled, which necessitates the use of a task.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Email Send Tasks have a one-to-one relation with Email
                    Sends. When a Send is created, either a Task is created to
                    send the Email immediately, or a Task is scheduled to handle
                    the send at a later time. Email Send Task records are
                    automatically cleaned up when a Send record is deleted.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Email Send Tasks are not created when performin a test send
                    of either an Email of an Email Draft.
                </Typography>
            </Grid>
        </Grid>
    );
};

export const DocletTaskBrowser: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Task Browser"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/tasks">Task Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    Tasks are long-running background processes. Read the Task
                    Documentation for details on Tasks.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The Task Browser shows lists of Tasks, grouped by the type of
                    Task.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The Render Pages and Send Email buttons at the top right can be
                    used to start Tasks manually. Read the documentation before
                    doing anything here. You probably don't need to be here.
                </Typography>
            </DocletBody>
        </div>
    );
};

export const DocletTaskDetail: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Task Detail"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/tasks">Task Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The Task Detail page shows details about a Task.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Tasks cannot be manipulated directly.
                </Typography>
            </DocletBody>
        </div>
    );
};

import React from 'react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';

import { CreateOrg } from '@acf-site/react-sdk/components/Orgs/CreateOrg';
import { UpdateOrg } from '@acf-site/react-sdk/components/Orgs/UpdateOrg';
import { DeleteOrg } from '@acf-site/react-sdk/components/Orgs/DeleteOrg';
import { CreateProject } from '@acf-site/react-sdk/components/Orgs/CreateProject';
import { DeleteProject } from '@acf-site/react-sdk/components/Orgs/DeleteProject';
import { CreateProjectYear } from '@acf-site/react-sdk/components/Orgs/CreateProjectYear';
import { DeleteProjectYear } from '@acf-site/react-sdk/components/Orgs/DeleteProjectYear';
import { UpdateProjectYear } from '@acf-site/react-sdk/components/Orgs/UpdateProjectYear';
import { BulkEnableOrgs } from '@acf-site/react-sdk/components/Orgs/BulkEnableOrgs';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import BrowserPage from './../../pages/admin/orgs/browse';
import DetailPage from './../../pages/admin/orgs/detail';
import ProjectDetailPage from './../../pages/admin/orgs/project';
import ProjectsByYear from './../../pages/admin/orgs/byYear';
import DataImport from './../../pages/admin/orgs/import';

import OrgIcon from '@material-ui/icons/Business';
import YearIcon from '@material-ui/icons/Today';
import ImportIcon from '@material-ui/icons/ImportExport';

const OrgsPageRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment>
            <CreateOrg/>
            <UpdateOrg/>
            <DeleteOrg/>
            <CreateProject/>
            <DeleteProject/>
            <CreateProjectYear/>
            <DeleteProjectYear/>
            <UpdateProjectYear/>
            <BulkEnableOrgs/>
            <Switch>
                <Route path="/admin/orgs/browse" render={() => <BrowserPage/>}/>
                <Route path="/admin/orgs/organization/" render={() => <DetailPage/>}/>
                <Route path="/admin/orgs/project/" render={() => <ProjectDetailPage/>}/>
                <Route path="/admin/orgs/projects-by-year" exact render={() => <ProjectsByYear/>}/>
                <Route path="/admin/orgs/data-import" exact render={() => <DataImport/>}/>
                <Redirect path="/admin/orgs" exact to="/admin/orgs/browse"/>
                <Redirect path="/admin/orgs/project" exact to="/admin/orgs/browse"/>
                <Redirect path="/admin/orgs/projects" exact to="/admin/orgs/browse"/>
            </Switch>
        </React.Fragment>
    );
};

const OrgsAppBarRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <Switch></Switch>
    );
};

const OrgsNavDrawerRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment>
            <Divider/>
            <List>
                <ListItem button component={Link} to={{ pathname: "/admin/orgs/browse" }}>
                    <ListItemIcon><OrgIcon/></ListItemIcon>
                    <ListItemText>Organizations</ListItemText>
                </ListItem>
                <ListItem button component={Link} to={{ pathname: "/admin/orgs/projects-by-year" }}>
                    <ListItemIcon><YearIcon/></ListItemIcon>
                    <ListItemText>Funding Years</ListItemText>
                </ListItem>
                <ListItem button component={Link} to={{ pathname: "/admin/orgs/data-import" }}>
                    <ListItemIcon><ImportIcon/></ListItemIcon>
                    <ListItemText>Data Import</ListItemText>
                </ListItem>
            </List>
        </React.Fragment>
    );
};

export { OrgsPageRoutes as PageRoutes, OrgsAppBarRoutes as AppBarRoutes, OrgsNavDrawerRoutes as NavDrawerRoutes };

import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import { useIntHash, useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { Root } from '@acf-site/client-sdk/lib/types';

import { Project } from '@acf-site/core-sdk/lib/Orgs/Project';
import { selectProject } from '@acf-site/client-sdk/lib/Orgs/Project/reducer';
import { ProjectDetail } from '@acf-site/react-sdk/components/Orgs/ProjectDetail';

import Typography from '@material-ui/core/Typography';

const PageProjectDetail: React.FunctionComponent = (): React.ReactElement => {
    const pid: number = useIntHash();
    const project: Project | null = useSelector((state: Root) => selectProject(state, pid));
    useTitle(project === null ? `Project ${pid}` : project.name);
    
    return (
        <React.Fragment>
            <ProjectDetail pid={pid}/>
            <TutorBox showKey="tutor-project-detail" showValue={-1} closeValue={100} nextValue={0} prereqKey="tutor-intro" prereqValue={39}>
                <Typography variant="h5">Project Detail</Typography>
                <Typography variant="body1">
                    This is the Project detail page, where you can view and
                    manage the details and funding records of a Project.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-project-detail" showValue={0} closeValue={100} nextValue={1}>
                <Typography variant="h5">Links</Typography>
                <Typography variant="body1">
                    The Links panel contains a link to the Project's Root
                    Directory (folder) in the File Explorer. Any files related
                    to the Project should be stored in this folder. The Project
                    folder will contain a folder for every year that the Project
                    has a Funding record. Files related to a specific year
                    should be kept in the appropriate folder, when possible.
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-project-detail" showValue={1} closeValue={2}>
                <Typography variant="h5">Funding</Typography>
                <Typography variant="body1">
                    The Project Funding panel shows all of the Funding records
                    for the Project. The + button in the top right of the 
                    Funding panel creates a new Funding Record. The Year of a
                    Funding record is always the year in which the grant was
                    approved.
                </Typography>
                <Typography variant="body1">
                    Recording Project Funding is important because it allows
                    the system to automatically generate lists of Projects
                    funded in a given year, total funding for an Organization,
                    and total funding for a given year.
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default withRouter(PageProjectDetail);

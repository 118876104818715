import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';

import { useTitle, useIntHash } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { YearPicker } from '@acf-site/react-sdk/components/Orgs/Utils/YearPicker';
import { YearBrowser } from '@acf-site/react-sdk/components/Orgs/YearBrowser';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const ProjectsByYear: React.FunctionComponent = (): React.ReactElement => {
    const history = useHistory();
    useTitle("Projects by Year");
    const year: number = useIntHash();
    
    const setYear = React.useCallback((year: number) => {
        history.push(`#${year}`)
    }, [history]);
    
    const [page, setPage] = React.useState<number>(0);
    
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <YearPicker
                        value={year}
                        variant="outlined"
                        label="Year"
                        id="ypkr-year-browser-year"
                        onChange={setYear}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Paper square>
                        <YearBrowser
                            year={year}
                            limit={100}
                            offset={page * 100}
                            onPageTurn={setPage}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default withRouter(ProjectsByYear);

import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { User } from '@acf-site/core-sdk/lib/Auth/User';

import { useHash, useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { Root } from '@acf-site/client-sdk/lib/types';
import { selectUser } from '@acf-site/client-sdk/lib/Auth/User/reducer';

import { UserDetail } from '@acf-site/react-sdk/components/Auth/UserDetail';

const PageUserDetail: React.FunctionComponent = (): React.ReactElement => {
    const uid: string = useHash();
    const user: User | null = useSelector((state: Root) => selectUser(state, uid));
    useTitle(user === null ? "User" + uid : user.name);
    
    return (
        <UserDetail uid={uid}/>
    );
};

export default withRouter(PageUserDetail);

import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { DocletHeading } from '@acf-site/react-sdk/components/Common/Docs/DocletHeading';
import { DocletBody } from '@acf-site/react-sdk/components/Common/Docs/DocletBody';

import { useStyles } from './index';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export const DocPages: React.FunctionComponent = (): React.ReactElement => {
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
                    <Link component={RouterLink} to="/admin/docs/">Docs</Link>
                    <Typography color="textPrimary">Pages</Typography>
                </Breadcrumbs>
                <Typography variant="h4">Pages</Typography>
                <Typography variant="body1" gutterBottom>
                    Pages represent "web pages" that make up the user-facing
                    web site found at <code>andersonchildrensfoundation.org</code>
                    and <code>acfgrants.org</code>.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Contents:</Typography>
                <ul>
                    <li><Link component={RouterLink} to="#paths">Page Paths</Link></li>
                    <li><Link component={RouterLink} to="#create">Creating Pages</Link></li>
                    <li><Link component={RouterLink} to="#drafts">Drafts</Link></li>
                    <li><Link component={RouterLink} to="#templates">Templates</Link></li>
                    <li><Link component={RouterLink} to="#rendering">Rendering</Link></li>
                </ul>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
                <Typography variant="h5" id="paths">Page Paths</Typography>
                <Typography variant="body1" gutterBottom>
                    A Page Path refers to the portion of the URL that leads to
                    the Page after the domain name. Everything in a URL after
                    the top-level domain (.com, .net, .org, etc.) and before
                    a <code>#</code> or <code>?</code> is considered part of the
                    path, including the leading <code>/</code>.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    In the Page Browser, Pages are organized by their path.
                    When a page is found with a <code>/</code> in its path,
                    everything before the <code>/</code> is considered to be
                    a folder containing the Page. Thus, a page with the
                    path <code>/foo/bar</code> would be considered to be in the
                    folder "foo."
                </Typography>
                <Typography variant="body1" gutterBottom>
                    When the Page Browser finds a folder, it shows is as a
                    folder, and ignores any other pages within that folder. When
                    the user clicks on the folder, the Page Browser then only
                    shows Pages within that folder, by checking if the folder's
                    path exists at the beginning of the Page's path.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Paths can only contain certain characters. To simplify
                    matters, the Admin Console restricts paths to alphanumeric
                    characters and underscore. Because of this restriction,
                    Pages that have illegal characters in their name will have a
                    path that differs from their name. For Pages where the path
                    is different from the name, the path used will be shown in a
                    light grey next to the display name of the Page.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    To create a Page in a folder that does not exist, you can
                    modify the path value when creating a Page.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
                <Typography variant="h5" id="create">Creating Pages</Typography>
                <Typography variant="body1" gutterBottom>
                    New Pages can be created using the + button at the bottom
                    left of the Page Browser. Clicking the + button opens the
                    Create Page dialog.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The Create Page dialog has two fields that need to be filled
                    in order to create a Page. The first is the name of the new
                    Page to create. The Page name can be anything, as long as it
                    results in a unique path. The second field is the path of
                    the new Page. The path field is filled in automatically
                    based on the path of the folder where the Create Page button
                    is pressed, and the name entered in the name field. The path
                    value can be changed in order to control which folder the
                    Page appears in.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
                <Typography variant="h5" id="drafts">Page Drafts</Typography>
                <Typography variant="body1" gutterBottom>
                    Page Drafts are used to edit Page content without affecting
                    the content that users see. A Page can have any number of
                    Drafts, as long as they all have different names.
                </Typography>
                <Typography variant="h6" id="drafts-create" className={classes.subheading}>Creating Drafts</Typography>
                <Typography variant="body1" gutterBottom>
                    Page Drafts can be created using the + button at the top of
                    the Drafts panel on the Page Detail page. Pressing the +
                    button opens the Create Draft dialog. The Create Draft
                    Dialog has 4 fields to be filled. The Draft name is used to
                    identify the Draft and only needs to unique among the drafts
                    of the parent Page. Clone another Draft is used to copy the
                    content of an existing Draft when creating the new Draft.
                    When cloning another Draft, the Draft to clone selector is
                    usedto choose a Draft to clone. The last field is the
                    Template selector, which is used to select a Page Template
                    to use for the new Draft.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Read the documentation on the <Link component={RouterLink} to="/admin/docs/editor">
                    Editor</Link> to learn about editing Drafts.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
                <Typography variant="h5" id="templates">Page Templates</Typography>
                <Typography variant="body1" gutterBottom>
                    Page Templates are used to manage standardized content
                    shared among many Pages.
                </Typography>
                <Typography variant="h6" className={classes.subheading}>Creating Page Templates</Typography>
                <Typography variant="body1" gutterBottom>
                    Page templates are created using the + button at the bottom
                    right of the Page Template Browser. The + button opens a
                    dialog to create a new Page Template. The Template Name
                    field is a unique name to identify the new template. The
                    Clone another Template checkbox and Template picker are used
                    to copy the content of an existing template when creating
                    the new template.
                </Typography>
                <Typography variant="h6" className={classes.subheading}>Using Page Templates</Typography>
                <Typography variant="body1" gutterBottom>
                    Page Templates are used by selecting the template in the
                    Template Picker in the Page Draft Editor. When the draft is
                    saved, the new template is applied. You can then preview the
                    draft with the new template, or publish it. The template
                    listed in the Page Detail is the template used when the page
                    was last published.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Page Templates differ from Pages, Emails, and Email
                    Templates in that they do not have drafts. This means that
                    when you save changes to a Page Template, those changes are
                    immediately applied to any Pages using the template. Any
                    Pages using the template will be <Link component={RouterLink} to="#rendering">
                    rendered</Link>.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
                <Typography variant="h5" id="rendering">Page Rendering</Typography>
                <Typography variant="body1" gutterBottom>
                    Page Rendering is the process of turning the editable
                    representation of a Page's content into the HTML that
                    viewers' browsers can understand. The rendering process
                    takes anywhere from a few seconds to almost a minute, and
                    thus is usually done in the background.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    When a Page is published, or when it's name or path is
                    changed, or when the Page's template is updated, the Page
                    is added to a queue of Pages waiting to be rendered. Pages
                    are rendered one by one in the order that they were queued.
                    Sometimes, when a large number of Pages need to be rendered
                    at once, for example when a template is updated,
                    a <Link component={RouterLink} to="/admin/docs/tasks">Page
                    Render Task</Link>.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    When a Page is rendered, it's content may not immediately
                    appear to all users on the public site. This is because of
                    how page content is delivered to viewers. In order to reduce
                    costs and to accelerate page load times, page content is
                    stored in cache servers spread out across the world. For
                    caching to be beneficial, these servers will store any
                    content for 24 hours without checking if the content has
                    changed. Thus it can take up to 24 hours for new Page
                    content to become visible to viewers.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The exception to the above (render queuing, render tasks,
                    and caching) is Page Draft previews. When the preview button
                    in the Page Draft Editor is pressed, the draft is
                    immediately rendered (regardless of the render queue) and
                    the content is never cached. Because the draft is rendered
                    on demand, it can take a few seconds for the preview to
                    open. Note that the preview may be blocked by your browser's
                    popup-blocker.
                </Typography>
            </Grid>
        </Grid>
    );
};

export const DocletPageBrowser: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Page Browser"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/pages">Pages Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The Page Browser is used to find Pages. Pages are arranged by
                    their <Link component={RouterLink} to="/admin/docs/pages#paths">
                    path </Link> into folders. The Page Browser shows the contents
                    of a single folder, starting with sub-folders followed by Pages
                    in alphabetical order.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Clicking on a folder will navigate into that folder, showing the
                    Pages and sub-folders of that folder. To return to a previous
                    folder, use your browser's back button, or jump to any parent
                    folder using the breadcrumbs at the top of the Page Browser.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Clicking on a Page opens the Page Detail view of that Page.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Some pages have a slightly different version of the Page name
                    in parenthesis next to the name. This is the name of the Page
                    according to the Page's path value, which is important for URLs.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The + button at the bottom right can be used to create a new
                    Page. See the <Link component={RouterLink} to="/admin/docs/pages#create">
                    documentation</Link> for more information on creating Pages.
                </Typography>
            </DocletBody>
        </div>
    );
};

export const DocletPageDetail: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Page Detail"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/pages">Pages Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The Page Detail page shows detailed information about a Page.
                    The summary panel shows basic informatino about the Page:
                    <ul>
                        <li>
                            Name is the name of the Page. The Page name is used to
                            identify the Page in the Admin Console, as well as in
                            the "title" of the page (the title of the page is shown
                            in the tab in a web browser).
                        </li>
                        <li>
                            The Page path shows both which folder the Page is found
                            in in the Page Browser, and the URL path where the page
                            is served. See the <Link component={RouterLink} to="/admin/docs/pages#paths">
                            documentation</Link> for more information on paths.
                        </li>
                        <li>
                            Enabled shows whether the Page is enabled and ready to
                            be viewed.
                        </li>
                        <li>
                            Template shows the Page Template that the Page was last
                            published with.
                        </li>
                        <li>
                            Last Updated and Updated By show when the Page metadata
                            was last changed and by whom.
                        </li>
                        <li>
                            Created and Created By show when the Page was created,
                            and who created it.
                        </li>
                        <li>
                            State shows the current state of the Page. The state can
                            be Ready, Queued, Rendering, Delayed, or Disabled.
                        </li>
                        <li>
                            Last Published shows when the Page's content was last
                            published.
                        </li>
                    </ul>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The Page References panel shows how the Page is connected to
                    other resources. This includes links between Pages, Files used
                    in the Page, and Organizations/Projects referenced by the Page.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The Drafts panel shows a list of drafts of the Page. Drafts
                    store different version of the Page's content. See
                    <Link component={RouterLink} to="/admin/docs/pages#drafts">Page
                    Drafts</Link> to learn more.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The View button at the top right of the Page Detail page opens
                    the current published version of the Page content.
                </Typography>
            </DocletBody>
        </div>
    );
};

export const DocletPageEditor: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Page Draft Editor"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1"><Link component={RouterLink} to="/admin/docs/pages#drafts">Page Draft Documentation</Link></Typography>
                <Typography variant="body1"><Link component={RouterLink} to="/admin/docs/editor">Editor Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/editor/components">Component Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The Page Draft Editor is used to erdit the content of a Page
                    Draft. See the documentation for details on how to use the
                    editor.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The Draft Template panel is unique to the Page Draft Editor. It
                    is used to choose a Page Template to use when rendering the
                    draft. Make sure to Save after changing templates to see the
                    effects.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The Preview button renders and opens the content of the draft.
                    Make sure to Save before creating a preview.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Read the Page Documentation for more details on Page Drafts,
                    Page Template, previews and rendering.
                </Typography>
            </DocletBody>
        </div>
    );
};

export const DocletTemplateBrowser: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Page Template Browser"/>
            <Divider/>
            <DocletBody>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/pages#templates">Page Template Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The Page Template Browser shows a list of all of the Page
                    Templates. Click on a template to see the Template's content.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The + button at the bottom right is used to create a new Page
                    Template.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    See the Page Documentation for more information on Page
                    Templates, and their creation and usage.
                </Typography>
            </DocletBody>
        </div>
    );
};

export const DocletTemplateEditor: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div>
            <DocletHeading text="Page Template Editor"/>
            <Divider/>
                <DocletBody>
                <Typography variant="body1"><Link component={RouterLink} to="/admin/docs/pages#template">Page Template Documentation</Link></Typography>
                <Typography variant="body1"><Link component={RouterLink} to="/admin/docs/editor">Editor Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom><Link component={RouterLink} to="/admin/docs/editor/components">Component Documentation</Link></Typography>
                <Typography variant="body1" gutterBottom>
                    The Page Template Editor is used to edit the content of a Page
                    Template. See the Editor documentation for details on how to
                    use the editor, and the Page documentation for details on Page
                    Templates and how to use them.
                </Typography>
            </DocletBody>
        </div>
    );
};

import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import { useIntHash, useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { Root } from '@acf-site/client-sdk/lib/types';

import { EmailTemplate } from '@acf-site/core-sdk/lib/Mail/EmailTemplate';
import { selectEmailTemplate } from '@acf-site/client-sdk/lib/Mail/EmailTemplate/reducer';

import { EmailTemplateDetail } from '@acf-site/react-sdk/components/Mail/EmailTemplateDetail';

import Typography from '@material-ui/core/Typography';

const PageEmailTemplateDetail: React.FunctionComponent = (): React.ReactElement => {
    const etid: number = useIntHash();
    const template: EmailTemplate | null = useSelector((state: Root) => selectEmailTemplate(state, etid));
    useTitle(template === null ? `Email Template ${etid}` : template.name);
    
    return (
        <React.Fragment>
            <EmailTemplateDetail etid={etid}/>
            <TutorBox showKey="tutor-email-template-detail" showValue={-1} closeValue={0} prereqKey="tutor-intro" prereqValue={39}>
                <Typography variant="h5">Email Template</Typography>
                <Typography variant="body1">
                    This is the Email Template detail page. It shows a basic
                    overview of an Email Template.
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default withRouter(PageEmailTemplateDetail);

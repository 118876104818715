import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

//import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import { useIntHash, useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { Root } from '@acf-site/client-sdk/lib/types';

import { EmailTemplateDraft } from '@acf-site/core-sdk/lib/Mail/EmailTemplateDraft'
import { selectEmailTemplateDraft } from '@acf-site/client-sdk/lib/Mail/EmailTemplateDraft/reducer';

import { EmailTemplateDraftDetail } from '@acf-site/react-sdk/components/Mail/EmailTemplateDraftDetail';

//import Typography from '@material-ui/core/Typography';

const PageEmailTemplateDraftDetail: React.FunctionComponent = () : React.ReactElement => {
    const etdid: number = useIntHash();
    const draft: EmailTemplateDraft | null = useSelector((state: Root) => selectEmailTemplateDraft(state, etdid));
    useTitle(draft === null ? `Email Template Draft ${etdid}` : draft.name);
    
    return (
        <React.Fragment>
            <EmailTemplateDraftDetail etdid={etdid}/>
        </React.Fragment>
    );
};

export default withRouter(PageEmailTemplateDraftDetail);

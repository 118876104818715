import React from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

import SignIn from './../../pages/auth/SignIn';
import ConfirmSignup from './../../pages/auth/ConfirmSignup';
import ForgotPassword from './../../pages/auth/ForgotPassword';

const BKG_WHITE: string = "data:image/gif;base64,R0lGODlhCAAIALMAAO/v7/Ly8vHx8ezs7Pb29u7u7vX19erq6vDw8AAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAAAAAAALAAAAAAIAAgAAAQcEIQZBLhgaGzLKYBAGFhIEEFJDWU2gGJ3mCNyRQA7";
const BKG_BLUE: string = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAI4SURBVEhLfZbbUsJAEETziSJ4e1UE1J9RUdSvjZxJztpsUT5MzWxPT/fuJqQYlo/78Wr7OV7vDiM1ebX5KMy83OzHm6evxgM3wC7X7zXLmrpmZl17A01JNokUImNEn6AmwN2gfIRzMxoPJbz7c9YoA7w/KRgz1vTlOndixEJAkiKLh7cK1oZc5+BrYu08mXl4A4AgOXdmQDTb4xbICDHn1cJhnVw02zMyaIIZEh1arCdhhcSJu5efWufG4ZURQC+smXUKy6N2rbC1142Bb2t7vXMIQtYpRMD1lKzVIMuVr3Z7GTJyKPHEFLDXrwlPRl2vN0XuwLXC9sTkZYB7E1nbH3z9aAqeuyrr/LGC96J5pfaJujrMkmSTyOOvttMbRIg559q+ZkYZcQIJ1IqnKTzWvbDYf0asy8hBr4ycAmQ4Ct8+f58YZa0JAUaPq64vA19nm95/Dputs891ukH71Bi6uTLqG/2OjBTJZ4oJuJm+pm4aXvsdOWhtzgBTiNpNsfZUGcmfrm5eMMQwtTtUyNpheIQ8cTO4HOLkW8dDJiOYO3Uz4n2fjJicMjs+d/TklRGR96kZeL5hcMARJytuwCmTY+0GxdozkuyD7gcJxcF6IzbhDBkdT0tuX2+HAfN09CWTweAZYDlrrSFYM7KZr22KaEIG7zmZ4acZQa8+qhogxGk8ycX9azNyQBGN+MEqCpZ8cGNQlAZBLWatUHKs7Z/jaEhMf7dmUjbAPBG1xnI104icG3Rmwg/jL/9PE/3gFDCsAAAAAElFTkSuQmCC";
const BKG_GREEN: string = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAALCAMAAABxsOwqAAAAFVBMVEWFlUqGlkqHl0qIl0qImEqJmEqJmUq1uDGLAAAALklEQVQI12NgwArYwIARxGSEs8BsRpgSRkYUHcxwFgsbC1QFK1AnhM3IBALIOgAhgQCJ2b3T4gAAAABJRU5ErkJggg==";

const PageRoutes: React.FunctionComponent = (): React.ReactElement => {
    const hist = useHistory();
    return (
        <div style={{ backgroundImage: `url(${BKG_WHITE})`, backgroundRepeat: 'repeat', height: '100vh' }}>
            <svg viewBox="0 0 600 1080" preserveAspectRatio="none" width="31.25vw" height="100vh" style={{ position: 'absolute', top: 0, left: 0 }}>
                <defs>
                    <filter id="f1" x="0" y="0" width="200%" height="200%">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="10"/>
                        <feOffset dx="2" dy="1"/>
                        <feMerge>
                            <feMergeNode/>
                            <feMergeNode in="SourceGraphic"/>
                        </feMerge>
                    </filter>
                    <pattern id="p1" patternUnits="userSpaceOnUse" width="26" height="26">
                        <image id="i1" width="26" height="26" xlinkHref={BKG_BLUE}/>
                    </pattern>
                </defs>
                <polygon fill="#87974a" filter="url(#f1)" points="-5,-5 550,-5 375,1085 -5,1085"/>
                <polygon fill="url(#p1)" filter="url(#f1)" points="-5,-5 520,-5 325,1085 -5,1085"/>
            </svg>
            <svg viewBox="0 0 600 1080" preserveAspectRatio="none" width="31.25vw" height="100vh" style={{ position: 'absolute', top: 0, right: 0 }}>
                <defs>
                    <filter id="f2" x="-1" y="-1" width="200%" height="200%">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="7"/>
                        <feOffset dx="-2" dy="-1"/>
                        <feMerge>
                            <feMergeNode/>
                            <feMergeNode in="SourceGraphic"/>
                        </feMerge>
                    </filter>
                    <pattern id="p2" patternUnits="userSpaceOnUse" width="10" height="11">
                        <image id="i2" width="10" height="11" xlinkHref={BKG_GREEN}/>
                    </pattern>
                </defs>
                <polygon fill="#657ab1" filter="url(#f2)" points="130,-5 605,-5 605,1085 20,1085"/>
                <polygon fill="url(#p2)" filter="url(#f2)" points="175,-5 605,-5 605,1085 50,1085"/>
            </svg>
            <div style={{ position: 'relative', top: '50vh', transform: 'translateY(-50%)' }}>
                <picture>
                    <source srcSet="https://d12h4atyt5k40p.cloudfront.net/5.webp.sm" type="image/webp" media="(max-width: 960px)"/>
                    <source srcSet="https://d12h4atyt5k40p.cloudfront.net/5.sm" media="(max-width: 960px)"/>
                    <source srcSet="https://d12h4atyt5k40p.cloudfront.net/4.webp.md" type="image/webp" media="(min-width: 960px)"/>
                    <img src="https://d12h4atyt5k40p.cloudfront.net/4.md" style={{ position: 'absolute', bottom: '105%', left: '50%', transform: 'translateX(-50%)', maxWidth: '90vw', maxHeight: '25vh' }} alt="ACF Logo"/>
                </picture>
                <Switch>
                    <Route path={`/auth/sign-in`} render={() => <SignIn/>}/>
                    <Route path={`/auth/confirm-signup`} render={() => <ConfirmSignup/>}/>
                    <Route path={`/auth/forgot-password`} render={() => <ForgotPassword/>}/>
                    <Route path={`/auth`} exact render={() => { console.log(`Redirecting to sign-in state=${JSON.stringify(hist.location.state)}`); return (<Redirect to={{ pathname: `/auth/sign-in`, state: hist.location.state }}/>); }}/>
                </Switch>
            </div>
        </div>
    );
}

export { PageRoutes };

import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { useStyles as useDocStyles } from './';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DocsIcon from '@material-ui/icons/HelpOutline';
import CloudIcon from '@material-ui/icons/Cloud';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import UserIcon from '@material-ui/icons/AccountCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ClearIcon from '@material-ui/icons/Clear';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import InboxIcon from '@material-ui/icons/Inbox';
import LockIcon from '@material-ui/icons/Lock';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cloudGridContainer: {
            padding: theme.spacing(2)
        },
        cloudGridItem: {
            display: 'flex'
        },
        grow: {
            flexGrow: 1
        }
    })
);

export const DocAppBar: React.FunctionComponent = (): React.ReactElement => {
    const docClasses = useDocStyles();
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb" className={docClasses.breadcrumbs}>
                    <Link component={RouterLink} to="/admin/docs/">Docs</Link>
                    <Typography color="textPrimary">App Bar</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4">App Bar</Typography>
                <Typography variant="body1" gutterBottom>
                    The blue bar at the top of the console is called the App
                    Bar. From left to right, the items in the App Bar are: the
                    Menu Button, the text "ACF Admin," the Search Bar, the
                    Docs Button, the Cloud Icon, and the User Button.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Contents:</Typography>
                <ul>
                    <li>
                        <Typography variant="subtitle2">App Bar</Typography>
                        <ul>
                            <li><Link component={RouterLink} to="#menu">Menu Button</Link></li>
                            <li><Link component={RouterLink} to="#admin">"ACF Admin"</Link></li>
                            <li><Link component={RouterLink} to="#search">Search Bar</Link></li>
                            <li><Link component={RouterLink} to="#docs">Doclet Drawer</Link></li>
                            <li><Link component={RouterLink} to="#cloud">Cloud Status</Link></li>
                            <li><Link component={RouterLink} to="#user">User Button</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Typography variant="subtitle2">User Menu</Typography>
                        <ul>
                            <li><Link component={RouterLink} to="#user">User</Link></li>
                            <li><Link component={RouterLink} to="#users">Users</Link></li>
                            <li><Link component={RouterLink} to="#web-mail">Web Mail</Link></li>
                            <li><Link component={RouterLink} to="#sign-out">Sign Out</Link></li>
                        </ul>
                    </li>
                </ul>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="menu">Menu Button <MenuIcon className={docClasses.inlineIcon}/></Typography>
                <Typography variant="body1" gutterBottom>
                    The Menu button is used to expand the main navigation menu.
                    The navigation menu is always shown as a ribon, but can be
                    expanded to show a label in addition to the usual icon.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    When the navigation menu is expanded, the menu button is
                    replaced by a <ChevronLeftIcon className={docClasses.inlineIcon}/> which collapses the menu.
                    Clicking anywhere outside of the navigation menu.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="admin">"ACF Admin"</Typography>
                <Typography variant="body1" gutterBottom>
                    The text "ACF Admin" is a link that can be clicked to get
                    back to the console home page.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="search">Search <SearchIcon className={docClasses.inlineIcon}/></Typography>
                <Typography variant="body1" gutterBottom>
                    The Search Bar can be used to search for almost any
                    resource. Pages, Files, Organizations, Projects, Emails,
                    Email Templates, and Mailing Lists can be searched for.
                    The search results appear in a drop-down, and can be clicked
                    on to jump to the resource. The search query is processed
                    as you type, yielding "instant" search results. The
                    <ClearIcon className={docClasses.inlineIcon}/> icon clears the search query. Click anywhere
                    outside of the search bar to close the search result
                    drop-down.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="docs">Docs <DocsIcon className={docClasses.inlineIcon}/></Typography>
                <Typography variant="body1" gutterBottom>
                    The Docs buton opens the Documentation Drawer. The
                    Documentation drawer contains information relevant to the
                    page on which it is opened. The content in the documentation
                    drawer may contain links to the full documentation.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="cloud">Cloud <CloudIcon className={docClasses.inlineIcon}/></Typography>
                <Typography variant="body1" gutterBottom>
                    The Cloud icon shows the status of the connection to the
                    server. The server connection can have one of three states.
                </Typography>
                <Paper elevation={0} variant="outlined">
                    <Grid container spacing={2} className={classes.cloudGridContainer}>
                        <Grid item xs={4} className={classes.cloudGridItem}>
                            <div className={classes.grow}></div>
                            <CircularProgress size={24}/>
                            <div className={classes.grow}></div>
                        </Grid>
                        <Grid item xs={4} className={classes.cloudGridItem}>
                            <div className={classes.grow}></div>
                            <CloudIcon/>
                            <div className={classes.grow}></div>
                        </Grid>
                        <Grid item xs={4} className={classes.cloudGridItem}>
                            <div className={classes.grow}></div>
                            <CloudOffIcon/>
                            <div className={classes.grow}></div>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography align="center">Connecting</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography align="center">Connected</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography align="center">Error</Typography>
                        </Grid>
                    </Grid>
                </Paper>
                <Typography variant="body1" gutterBottom>
                    When the server connection is in the Connecting state, most
                    functions of the Admin Console will not work. Any attempt to
                    communicate with the server will block (wait) until either
                    the server connection is established, or the attempt to
                    connect to the server times out.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    When the connection has an error, any attempt to communicate
                    with the server will fail. If you see the connection error
                    icon, try refreshing the page to establish a new connection.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="user">User <UserIcon className={docClasses.inlineIcon}/></Typography>
                <Typography variant="body1" gutterBottom>
                    The User button opens the User Menu, described below.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h4" id="user-menu">User Menu</Typography>
                <Typography variant="body1" gutterBottom>
                    The User Menu, opened by clicking the User button in the
                    App Bar, provides a number of functions related to Admin
                    Console users, primarily the current user.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="user">User <PersonIcon className={docClasses.inlineIcon}/></Typography>
                <Typography variant="body1" gutterBottom>
                    The first item in the User Menu is the current user. This
                    leads to the <Link component={RouterLink} to="/admin/docs/auth/user">
                    User Detail</Link> page for the current user.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="users">Users <PeopleIcon className={docClasses.inlineIcon}/></Typography>
                <Typography variant="body1" gutterBottom>
                    The Users item leads to the <Link component={RouterLink} to="/admin/docs/auth/users">
                    User Browser
                    </Link> page.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="web-mail">Web Mail <InboxIcon className={docClasses.inlineIcon}/></Typography>
                <Typography variant="body1" gutterBottom>
                    The Web Mail item opens the ACF Web Mail interface. The Web
                    Mail interface is the only way to change your ACF Email
                    password.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="sign-out">Sign Out <LockIcon className={docClasses.inlineIcon}/></Typography>
                <Typography variant="body1" gutterBottom>
                    The Sign Out button is used to sign out of the Admin Console.
                </Typography>
            </Grid>
        </Grid>
    );
};

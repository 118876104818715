import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useIntHash, useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import { Root } from '@acf-site/client-sdk/lib/types';

import { Page } from '@acf-site/core-sdk/lib/Pages/Page';
import { selectPage } from '@acf-site/client-sdk/lib/Pages/Page/reducer';

import { PageDetail } from '@acf-site/react-sdk/components/Pages/Ui/PageDetail';

import Typography from '@material-ui/core/Typography';

const DRAFT_QUERY_REGEXP: RegExp = /(?:draft=)(\d+)/;

const PagePageDetail: React.FunctionComponent = (): React.ReactElement => {
    const history = useHistory();
    
    const location = history.location;
    const pid: number = useIntHash();
    const did: number | undefined = React.useMemo<number | undefined>(() => {
        let match = DRAFT_QUERY_REGEXP.exec(location.search);
        if (match !== null) {
            return Number.parseInt(match[1]);
            
        } else {
            return undefined;
        }
        
    }, [location]);
    
    const page: Page | null = useSelector((state: Root) => selectPage(state, pid));
    
    useTitle(page === null ? `Page ${pid}` : page.name);
    
    type DraftSelectionHandler = (did: number) => void;
    const onDraftSelected: DraftSelectionHandler = React.useCallback<DraftSelectionHandler>((did: number) => {
        history.replace(`/admin/pages/page/#${pid}?draft=${did}`);
        
    }, [history, pid]);
    
    return (
        <React.Fragment>
            <PageDetail pid={pid} did={did} onDraftSelected={onDraftSelected}/>
            <TutorBox showKey="tutor-pages-detail" showValue={-1} closeValue={1} prereqKey="tutor-intro" prereqValue={49}>
                <Typography variant="h5">Page Detail</Typography>
                <Typography variant="body1">
                    This is the Page Detail page. It shows all of the details of
                    a Page, except for its content.
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default withRouter(PagePageDetail);

import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';

import { useIntHash, useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import { Task } from '@acf-site/core-sdk/lib/Common/Task';
import { Root } from '@acf-site/client-sdk/lib/types';
import { selectTask } from '@acf-site/client-sdk/lib/Common/Task/reducer';

import { TaskDetail } from '@acf-site/react-sdk/components/Common/TaskDetail';

import Typography from '@material-ui/core/Typography';

const PageTaskDetail: React.FunctionComponent = (): React.ReactElement => {
    const tid: number = useIntHash();
    const task: Task | null = useSelector((state: Root) => selectTask(state, tid));
    useTitle(task === null ? `Task ${tid}` : task.name);
    
    return (
        <React.Fragment>
            <TaskDetail tid={tid}/>
            <TutorBox showKey="tutor-task-intro" showValue={-1} closeValue={0} prereqKey="tutor-intro" prereqValue={39}>
                <Typography variant="h5">Tasks</Typography>
                <Typography variant="body1">
                    Tasks are long-running background processes that
                    autonomously perform heavy tasks. 
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default withRouter(PageTaskDetail);

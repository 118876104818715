/*
 * @acf-site/react-app/index.tsx
 * Generated at Fri Jul 24 2020 22:29:28 GMT+0000 (Coordinated Universal Time) 
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Amplify from 'aws-amplify';
import App from './App';
import AmplifyConfiguration from './config/amplify.json';
import { store } from '@acf-site/client-sdk/lib/store';
/* <TemplateEditableSection name="file-index.tsx-header"> */
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';

import { updateAuthenticated } from '@acf-site/client-sdk/lib/Auth/State/actions/updateAuthenticated';
import { updateMe } from '@acf-site/client-sdk/lib/Auth/State/actions/updateMe';

import AcfTheme from './config/theme.json';

(AmplifyConfiguration.API.endpoints as Array<{[key: string]: any, custom_header?: Function}>)[0]['custom_header'] = async () => {
	return {
		authorization: (await Amplify.Auth.currentSession()).getAccessToken().getJwtToken()
	};
};

/* </TemplateEditableSection> */


/* <TemplateEditableSection name="render-app"> */
Amplify.configure(AmplifyConfiguration);

const theme = createMuiTheme(AcfTheme);

const rootElement = document.getElementById("root");
ReactDOM.render(
	<ThemeProvider theme={theme}>
		<Provider store={store}>
			<CssBaseline/>
			<Router>
				<App/>
			</Router>
		</Provider>
	</ThemeProvider>,
	rootElement
);

Amplify.Auth.currentAuthenticatedUser()
	.then((user: any) => {
		if (user === undefined || user === null) return;
		else {
			store.dispatch(updateMe(user['attributes']['sub']));
			store.dispatch(updateAuthenticated(true));
		}
	});

/* </TemplateEditableSection> */
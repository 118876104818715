import React from 'react';
import { useRouteMatch, useHistory, match } from 'react-router-dom';

import { ForgotPassword } from '@acf-site/react-sdk/components/Auth/ForgotPassword';

import Container from '@material-ui/core/Container';

export interface ForgotPasswordParams {
    user?: string;
}

const Page: React.FunctionComponent = (): React.ReactElement => {
    React.useEffect(() => {
        document.title = "Confirm Sign Up - ACF Admin";
    });
    
    let { params } = useRouteMatch<ForgotPasswordParams>() as match<ForgotPasswordParams>;
    
    const history = useHistory();
    
    return (
        <Container maxWidth="sm">
            <ForgotPassword defaultUsername={params.user} onCancel={() => history.goBack()} onComplete={() => history.push(`/auth`)}/>
        </Container>
    );
};

export default Page;

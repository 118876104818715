import React from 'react';
import { Switch, Route, Link, Redirect } from 'react-router-dom';

import { CreateDir as CreateDirDialog } from '@acf-site/react-sdk/components/Files/CreateDir';
import { DirectoryPickerWrapper } from '@acf-site/react-sdk/components/Files/DirectoryPickerWrapper';
import { FilePull as PullFilesDialog } from '@acf-site/react-sdk/components/Files/UiState/FilePull/FilePull';
import { RenameFile as RenameFileDialog } from '@acf-site/react-sdk/components/Files/RenameFile';
import { FileUpload as FileUploadDialog } from '@acf-site/react-sdk/components/Files/FileUpload';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import TrashIcon from '@material-ui/icons/Delete';
import FolderIcon from '@material-ui/icons/Folder';

import BrowsePage from './../../pages/admin/files/browse';

const FilesPageRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment>
            <CreateDirDialog/>
            <DirectoryPickerWrapper/>
            <PullFilesDialog/>
            <RenameFileDialog/>
            <FileUploadDialog/>
            <Switch>
                <Route path={`/admin/files/browse`} render={() => <BrowsePage/>}/>
                <Redirect path={`/admin/files`} exact to={`/admin/files/browse`}/>
            </Switch>
        </React.Fragment>
    );
};

const FilesAppBarRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <Switch>
            
        </Switch>
    );
};

const FilesNavDrawerRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment>
            <Divider/>
            <List>
                <ListItem button key="root" component={Link} to={{ pathname: `/admin/files/browse`, hash: "0" }}>
                    <ListItemIcon><FolderIcon/></ListItemIcon>
                    <ListItemText>Root</ListItemText>
                </ListItem>
                <ListItem button key="trash" component={Link} to={{ pathname: `/admin/files/browse`, hash: "-1" }}>
                    <ListItemIcon><TrashIcon/></ListItemIcon>
                    <ListItemText>Trash</ListItemText>
                </ListItem>
            </List>
        </React.Fragment>
    );
};

export { FilesPageRoutes as PageRoutes, FilesAppBarRoutes as AppBarRoutes, FilesNavDrawerRoutes as NavDrawerRoutes };

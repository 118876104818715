import React from 'react';

import { useHistory } from 'react-router-dom';

import Versions from '../../config/version.json';

import { TutorBuble } from '@acf-site/react-sdk/components/Common/Docs/TutorBuble';

import { EmbeddedGlobalSearch } from '@acf-site/react-sdk/components/Search/Sdk/EmbeddedGlobalSearch';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import HelpIcon from '@material-ui/icons/Help';

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      //whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    drawerPermanent: {
      "& .MuiListItemText-primary": {
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      }
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    heading: {
      cursor: 'pointer',
      [theme.breakpoints.only('xs')]: {
        display: 'none'
      }
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    grow: {
      flexGrow: 1
    },
    searchGrow: {
        flexGrow: 1
    },
    spacerGrow: {
        flexGrow: 0,
        [theme.breakpoints.up('md')]: { flexGrow: 1 },
        [theme.breakpoints.up('lg')]: { flexGrow: 2 },
        [theme.breakpoints.up('xl')]: { flexGrow: 3 },
    },
    versions: {
      padding: theme.spacing(1),
      color: theme.palette.text.disabled,
      textAlign: 'end',
      '& span': {
        display: 'block'
      },
      '& $versionsHidden': {
        display: 'none'
      },
      '&:hover $versionsHidden': {
        display: 'block'
      }
    },
    versionsHidden: {}
  })
);

export interface AuthFrameProps {
    children: {
        pageRoutes: NonNullable<React.ReactNode>;
        appBarRoutes: React.ReactNode;
        navDrawerRoutes: React.ReactNode;
        docletRoutes: React.ReactNode;
    };
}

const AuthFrame: React.FunctionComponent<AuthFrameProps> = (props: React.PropsWithChildren<AuthFrameProps>): React.ReactElement => {
    const history = useHistory();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [ropen, setROpen] = React.useState(false);
    
    const { pageRoutes, appBarRoutes, navDrawerRoutes, docletRoutes } = props.children;
    
    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                className={classes.appBar}
                >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        onClick={() => setOpen(true)}
                        edge="start"
                        className={classes.menuButton}
                        >
                        <MenuIcon/>
                        <TutorBuble showKey="tutor-intro" showValue={0} innerRadius={32}/>
                    </IconButton>
                    <Typography variant="h6" noWrap className={classes.heading} onClick={() => history.push('/admin')}>ACF Admin</Typography>
                    <div className={classes.spacerGrow}></div>
                    <EmbeddedGlobalSearch className={classes.searchGrow}/>
                    <div className={classes.spacerGrow}></div>
                    <IconButton
                      color="inherit"
                      onClick={React.useCallback(() => setROpen(true), [setROpen])}
                      >
                      <HelpIcon/>
                      <TutorBuble showKey="tutor-intro" showValue={9}/>
                    </IconButton>
                    {appBarRoutes}
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={`${classes.drawer} ${classes.drawerClose} ${classes.drawerPermanent}`}
                classes={{
                  paper: classes.drawerClose
                }}
                >
                <div className={classes.toolbar}>
                  <IconButton
                    onClick={() => setOpen(true)}
                    className={classes.menuButton}
                    >
                    <MenuIcon/>
                  </IconButton>
                </div>
                <Divider/>
                {navDrawerRoutes}
            </Drawer>
            <Drawer
                className={`${classes.drawer} ${classes.drawerOpen}`}
                classes={{
                    paper: classes.drawerOpen
                }}
                open={open}
                onClose={() => setOpen(false)}
                >
                <div className={classes.toolbar}>
                    <IconButton onClick={() => setOpen(false)}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </div>
                <Divider/>
                {navDrawerRoutes}
                <div className={classes.grow}></div>
                <div className={classes.versions}>
                  <span>version: 0.{Versions['react-project']}</span>
                  <span className={classes.versionsHidden}>sdk: 0.{Versions['react-sdk']}</span>
                  <span className={classes.versionsHidden}>core: 0.{Versions['core-sdk']}</span>
                </div>
            </Drawer>
            <Drawer
              className={`${classes.drawer} ${classes.drawerOpen}`}
              classes={{
                paper: classes.drawerOpen
              }}
              open={ropen}
              onClose={() => setROpen(false)}
              anchor="right"
              >
              {docletRoutes}
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar}/>
                <Container maxWidth="xl" component="div">
                  {pageRoutes}
                </Container>
            </main>
        </div>
    );
    
};

export default AuthFrame;

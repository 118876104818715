import React from 'react';

import AppRoutes from './routes';

const App: React.FC = () => {
  return (
    <AppRoutes/>
  );
}

export default App;

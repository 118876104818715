import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { TutorBox } from '@acf-site/react-sdk/components/Common/Docs/TutorBox';
import { TutorBuble } from '@acf-site/react-sdk/components/Common/Docs/TutorBuble';

import { CreateMailingList } from '@acf-site/client-sdk/lib/Mail/CreateMailingList/sagas/CreateMailingList';

import { MailingListBrowser } from '@acf-site/react-sdk/components/Mail/MailingListBrowser';

import { useStyles, useTitle } from '@acf-site/react-sdk/components/Common/Page/PageBase';

import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';

const PageMailingListBrowse: React.FunctionComponent = (): React.ReactElement => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    useTitle("Mailing Lists");
    
    const onMailingListSelected = React.useCallback((mlid: number) => {
        history.push(`/admin/mail/list/#${mlid}`);
        
    }, [history]);
    
    const [page, setPage] = React.useState<number>(0);
    
    return (
        <React.Fragment>
            <MailingListBrowser
                limit={100}
                offset={page * 100}
                onMailingListSelected={onMailingListSelected}
                onPageTurn={setPage}
            />
            <div className={classes.fabContainer}>
                <Fab color="primary" aria-label="Create Mailing List" title="Create Mailing List" onClick={() => dispatch(CreateMailingList(true))}>
                    <AddIcon/>
                    <TutorBuble showKey="tutor-mailing-list-intro" showValue={0}/>
                </Fab>
            </div>
            <TutorBox showKey="tutor-mailing-list-intro" showValue={-1} closeValue={100} nextValue={0} prereqKey="tutor-intro" prereqValue={29}>
                <Typography variant="h5">Mailing Lists</Typography>
                <Typography variant="body1">
                    Mailing Lists are lists of email addresses that Emails are
                    sent to. 
                </Typography>
            </TutorBox>
            <TutorBox showKey="tutor-mailing-list-intro" showValue={0} closeValue={1}>
                <Typography variant="h5">Create Mailing List</Typography>
                <Typography variant="body1">
                    The + button is used to create a new Mailing List.
                </Typography>
            </TutorBox>
        </React.Fragment>
    );
};

export default withRouter(PageMailingListBrowse);

import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { useStyles as useDocStyles } from './index';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { red, purple, blue, cyan, blueGrey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid1Cont: {
            backgroundColor: blueGrey[500],
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1),
            margin: theme.spacing(1, 0)
        },
        grid1Item: {
            backgroundColor: blueGrey[300],
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1)
        },
        grid2Cont: {
            backgroundColor: red[500],
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1),
            margin: theme.spacing(1, 0)
        },
        grid2Item: {
            backgroundColor: red[300],
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1)
        },
        grid3Cont: {
            backgroundColor: purple[500],
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1),
            margin: theme.spacing(1, 0)
        },
        grid3Item: {
            backgroundColor: purple[300],
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1)
        },
        grid4Cont: {
            backgroundColor: blue[500],
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1),
            margin: theme.spacing(1, 0)
        },
        grid4Item: {
            backgroundColor: blue[300],
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1)
        },
        grid5Cont: {
            backgroundColor: cyan[500],
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1),
            margin: theme.spacing(1, 0)
        },
        grid5Item: {
            backgroundColor: cyan[300],
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1)
        }
    })
);

export const DocEditor: React.FunctionComponent = (): React.ReactElement => {
    const classes = useStyles();
    const docClasses = useDocStyles();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb" className={docClasses.breadcrumbs}>
                    <Link component={RouterLink} to="/auth/docs/">Docs</Link>
                    <Typography color="textPrimary">Editor</Typography>
                </Breadcrumbs>
                <Typography variant="h4">Editor</Typography>
                <Typography variant="body1">
                    The Editor is used to edit content of Page Drafts, Page
                    Templates, Email Drafts and Email Template Drafts. While
                    there are minor differences for editing each type of
                    resource, the majority of the Editor functinoality is the
                    same.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Contents:</Typography>
                <ul>
                    <li><Link component={RouterLink} to="#components">Components</Link></li>
                    <li><Link component={RouterLink} to="#interface">Editor Interface</Link></li>
                    <li><Link component={RouterLink} to="#props">Component Properties</Link></li>
                    <li><Link component={RouterLink} to="#palettes">Component Palettes</Link></li>
                    <li><Link component={RouterLink} to="#types">Component Types</Link></li>
                </ul>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="components">Components</Typography>
                <Typography variant="body1" gutterBottom>
                    The Editor is designed around the idea of Components.
                    Components represent visual elements that have a distinct
                    purpose. Images, headings, and blocks of text are examples
                    of Components.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Components can have properties that control some aspect of
                    their behavior. Component properties are specific to a
                    single type of Component.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Components are placed within another component, known as the
                    parent. The Components within a parent are the parent's
                    children, and are eachother's siblings. Some Components
                    only allow certain Component types as children. Whether the
                    type or properties of a parent Component impacts the
                    appearance of a child Component depends on the specific
                    Components in question.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="interface">Editor Interface</Typography>
                <Typography variant="body1" gutterBottom>
                    The Editor consists of two main areas, the Component Palette
                    on the left, and the editable content on the right. Page
                    Draft and Email Draft editors also have a Template Selector
                    above the Component Palette.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The Component Palette is a categorized list of all of the
                    Component types that are available in the editor. Which
                    Components can be used varies depending on what is being
                    edited. See <Link component={RouterLink} to="#palettes">
                    Component Palettes</Link> for more information on Component
                    availability. To add a Component to the editable content,
                    click and drag the desired Component from the Component
                    Palette. When a Component is being dragged, the "drop-zones"
                    that can accept the dragged Component type will be
                    highlighted in green. To place the Component, just drop it
                    in the desired drop-zone. Components can only be dropped
                    into parent Components that accept that Component type as a
                    child.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Components that are in the editable content show all of
                    their Properties and their children. Each Component Type
                    is color coded such that similar or related Components have
                    similar colors. Across the top of a Component are 5
                    important items. 
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The first is the Component Icon. Most Component types have a
                    unique icon to identify them. Some tightly related
                    Components, however, have the same icon. The icon can be
                    used as a handle to drag-and-drop the Component to a new
                    location in the editable content. Like when dragging a
                    Component from the Palette, the acceptable drop-zones are
                    highlighted green. Next to the Component Icon is the
                    Component Type as text.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    At the top right of a Component are three buttons. The first
                    is the edit button, which is used to edit the Properties of
                    the Component. When the Component is in "edit mode" the
                    properties area becomes white, and the properties can be
                    edited. The specific properties available to edit depend
                    on the Component type being edited. The edit button becomes
                    a checkmark when in edit mode. Make sure to press it to
                    apply and changes made to the Component's properties.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    After the Edit button is the Delete button, which removes
                    the Component and all of its children from the editor. The
                    Root component cannot be deleted.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The last button is the collapse/expand button. Collapsing a
                    component hides the contents of the Component, including
                    its children. This is useful when editing large documents.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="props">Component Properties</Typography>
                <Typography variant="body1" gutterBottom>
                    Properties are values which control the behavior and/or
                    appearance of a Component. Properties and their functions
                    are specific to each Component type.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="palettes">Component Palettes</Typography>
                <Typography variant="body1" gutterBottom>
                    Component Palettes refer to the sets of Components that are
                    available to use in a given document type. Different
                    document types use different Palettes because some Component
                    types either don't make sense to use in some documents, or
                    cannot be implemented in them.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-root">Root</Typography>
                <Typography variant="body1" gutterBottom>
                    The Root component is unique in that every editable document
                    has one and only one Root.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="body1" gutterBottom>
                    The Root Component has no properties.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-template-fill">Template Fill</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-grid-container">Grid Container</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-bottom-nav">Bottom Navigation</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-nav-drawer">Navigation Drawer</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-app-bar">App Bar</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-paper">Paper</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-expansion-panel-group">Expansion Panel Group</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-divider">Divider</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-heading">Heading</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-image">Image</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-image-gallery">Image Gallery</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-image-carouser">Image Carousel</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-block-quote">Block Quote</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-rich-text">Text</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-container">Container</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-projects-by-year">Projects by Year</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-acf-section">ACF Section</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-acf-sequence">ACF Sequence</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-acf-sequence-item">ACF Sequence Item</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-subscribe-form">Subscribe Form</Link></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-template-fill">Template Fill</Typography>
                <Typography variant="body1" gutterBottom>
                    The Template Fill Component is only used in Page Templates
                    and Email Templates. During rendering, the Template Fill
                    component is replaced with the Root of the document being
                    rendered. Every Template must have one and only one Template
                    Fill Component.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="body1" gutterBottom>
                    The Template Fill Component has no properties.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Typography variant="body1" gutterBottom>
                    The Template Fill Component accepts no children.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-grid-container">Grid Container</Typography>
                <Typography variant="body1" gutterBottom>
                    The Grid Container Component is used with the Grid Item
                    Component to implement a 12 Column Grid layout.
                </Typography>
                <Typography variant="h6">Properties</Typography>
                <Typography variant="subtitle1">Spacing</Typography>
                <Typography variant="body1" gutterBottom>
                    The Spacing property determines the amount of space between
                    Grid Items. It it usually not necessary to change this
                    property.
                </Typography>
                <Typography variant="h6">Child Components</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-grid-item">Grid Item</Link></Grid>
                </Grid>
                <Typography variant="h6">12 Column Grid Layout</Typography>
                <Typography variant="body1" gutterBottom>
                    The 12 Column Grid is a layout paradigm used in responsive
                    designs (designs that adapt to difference device sizes).
                    In a 12 Column Grid, each Grid Item's width is described
                    as a number of columns. There are 12 columns in each row,
                    thus allowing an item to be any number of 12ths of the
                    grid's total width. The use of 12ths allows an item to be
                    a whole, half, third, or quarter of the whole grid's width.
                    Additionally, each Grid Item specifies its width for five
                    different device sizes: extra small, small, medium, large,
                    and extra large. By specifying different widths for
                    different device sizes, one can implement a responsive
                    layout that properly adapts to any device. For example,
                    a series of four Grid Items could appear as a single row
                    on very large devices, three items on one row and one on a
                    second on medium devices, two rows of two items on smaller
                    devices, and four rows of one item on very small devices.
                </Typography>
                <Typography variant="body1">
                    The diagram below shows how the 12 column grid can be used
                    to produce different fractions of the full width.
                </Typography>
                <Grid container spacing={1} className={classes.grid1Cont}>
                    <Grid item xs={12}><Typography className={classes.grid1Item} variant="body1" align="center">12</Typography></Grid>
                    <Grid item xs={6}><Typography className={classes.grid1Item} variant="body1" align="center">6</Typography></Grid>
                    <Grid item xs={6}><Typography className={classes.grid1Item} variant="body1" align="center">6</Typography></Grid>
                    <Grid item xs={4}><Typography className={classes.grid1Item} variant="body1" align="center">4</Typography></Grid>
                    <Grid item xs={4}><Typography className={classes.grid1Item} variant="body1" align="center">4</Typography></Grid>
                    <Grid item xs={4}><Typography className={classes.grid1Item} variant="body1" align="center">4</Typography></Grid>
                    <Grid item xs={3}><Typography className={classes.grid1Item} variant="body1" align="center">3</Typography></Grid>
                    <Grid item xs={3}><Typography className={classes.grid1Item} variant="body1" align="center">3</Typography></Grid>
                    <Grid item xs={3}><Typography className={classes.grid1Item} variant="body1" align="center">3</Typography></Grid>
                    <Grid item xs={3}><Typography className={classes.grid1Item} variant="body1" align="center">3</Typography></Grid>
                    <Grid item xs={2}><Typography className={classes.grid1Item} variant="body1" align="center">2</Typography></Grid>
                    <Grid item xs={2}><Typography className={classes.grid1Item} variant="body1" align="center">2</Typography></Grid>
                    <Grid item xs={2}><Typography className={classes.grid1Item} variant="body1" align="center">2</Typography></Grid>
                    <Grid item xs={2}><Typography className={classes.grid1Item} variant="body1" align="center">2</Typography></Grid>
                    <Grid item xs={2}><Typography className={classes.grid1Item} variant="body1" align="center">2</Typography></Grid>
                    <Grid item xs={2}><Typography className={classes.grid1Item} variant="body1" align="center">2</Typography></Grid>
                    <Grid item xs={1}><Typography className={classes.grid1Item} variant="body1" align="center">1</Typography></Grid>
                    <Grid item xs={1}><Typography className={classes.grid1Item} variant="body1" align="center">1</Typography></Grid>
                    <Grid item xs={1}><Typography className={classes.grid1Item} variant="body1" align="center">1</Typography></Grid>
                    <Grid item xs={1}><Typography className={classes.grid1Item} variant="body1" align="center">1</Typography></Grid>
                    <Grid item xs={1}><Typography className={classes.grid1Item} variant="body1" align="center">1</Typography></Grid>
                    <Grid item xs={1}><Typography className={classes.grid1Item} variant="body1" align="center">1</Typography></Grid>
                    <Grid item xs={1}><Typography className={classes.grid1Item} variant="body1" align="center">1</Typography></Grid>
                    <Grid item xs={1}><Typography className={classes.grid1Item} variant="body1" align="center">1</Typography></Grid>
                    <Grid item xs={1}><Typography className={classes.grid1Item} variant="body1" align="center">1</Typography></Grid>
                    <Grid item xs={1}><Typography className={classes.grid1Item} variant="body1" align="center">1</Typography></Grid>
                    <Grid item xs={1}><Typography className={classes.grid1Item} variant="body1" align="center">1</Typography></Grid>
                    <Grid item xs={1}><Typography className={classes.grid1Item} variant="body1" align="center">1</Typography></Grid>
                </Grid>
                <Typography variant="body1">
                    The diagrams below show how the 12 Column Grid can be used to make a responsive layout.
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2">Example Large Layout</Typography>
                        <Grid container spacing={1} className={classes.grid2Cont}>
                            <Grid item xs={3}><Typography className={classes.grid2Item} variant="body1" align="center">3</Typography></Grid>
                            <Grid item xs={3}><Typography className={classes.grid2Item} variant="body1" align="center">3</Typography></Grid>
                            <Grid item xs={3}><Typography className={classes.grid2Item} variant="body1" align="center">3</Typography></Grid>
                            <Grid item xs={3}><Typography className={classes.grid2Item} variant="body1" align="center">3</Typography></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2">Example Medium Layout</Typography>
                        <Grid container spacing={1} className={classes.grid3Cont}>
                            <Grid item xs={4}><Typography className={classes.grid3Item} variant="body1" align="center">4</Typography></Grid>
                            <Grid item xs={4}><Typography className={classes.grid3Item} variant="body1" align="center">4</Typography></Grid>
                            <Grid item xs={4}><Typography className={classes.grid3Item} variant="body1" align="center">4</Typography></Grid>
                            <Grid item xs={12}><Typography className={classes.grid3Item} variant="body1" align="center">12</Typography></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2">Example Small Layout</Typography>
                        <Grid container spacing={1} className={classes.grid4Cont}>
                            <Grid item xs={6}><Typography className={classes.grid4Item} variant="body1" align="center">6</Typography></Grid>
                            <Grid item xs={6}><Typography className={classes.grid4Item} variant="body1" align="center">6</Typography></Grid>
                            <Grid item xs={6}><Typography className={classes.grid4Item} variant="body1" align="center">6</Typography></Grid>
                            <Grid item xs={6}><Typography className={classes.grid4Item} variant="body1" align="center">6</Typography></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2">Example Extra Small Layout</Typography>
                        <Grid container spacing={1} className={classes.grid5Cont}>
                            <Grid item xs={12}><Typography className={classes.grid5Item} variant="body1" align="center">12</Typography></Grid>
                            <Grid item xs={12}><Typography className={classes.grid5Item} variant="body1" align="center">12</Typography></Grid>
                            <Grid item xs={12}><Typography className={classes.grid5Item} variant="body1" align="center">12</Typography></Grid>
                            <Grid item xs={12}><Typography className={classes.grid5Item} variant="body1" align="center">12</Typography></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-grid-item">Grid Item</Typography>
                <Typography variant="body1" gutterBottom>
                    The Grid Item Component is used in conjunction with the Grid
                    Container Component to implement a 12 Column Grid layout.
                    Read the Grid Container documentation for information on
                    using a 12 Column Layout.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="subtitle1" className={docClasses.subheading}>XS, SM, MD, LG, XL</Typography>
                <Typography variant="body1" gutterBottom>
                    The number of columns that the Grid Item should occupy on
                    extra small, small, medium, large, and extra large devices.
                </Typography>
                <Typography variant="subtitle1" className={docClasses.subheading}>Hidden Below, Hidden Above</Typography>
                <Typography variant="body1" gutterBottom>
                    When set, the Grid Item is hidden on devices smaller or
                    larger than the specified size.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={4} md={3} xl={2}><Link component={RouterLink} to="#types-template-fill">Template Fill</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} xl={2}><Link component={RouterLink} to="#types-grid-container">Grid Container</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} xl={2}><Link component={RouterLink} to="#types-paper">Paper</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} xl={2}><Link component={RouterLink} to="#types-expansion-panel-group">Expansion Panel Group</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} xl={2}><Link component={RouterLink} to="#types-divider">Divider</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} xl={2}><Link component={RouterLink} to="#types-heading">Heading</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} xl={2}><Link component={RouterLink} to="#types-image">Image</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} xl={2}><Link component={RouterLink} to="#types-image-gallery">Image Gallery</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} xl={2}><Link component={RouterLink} to="#types-image-carousel">Image Carousel</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} xl={2}><Link component={RouterLink} to="#types-block-quote">Block Quote</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} xl={2}><Link component={RouterLink} to="#types-rich-text">Text</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} xl={2}><Link component={RouterLink} to="#types-projects-by-year">Projects by Year</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} xl={2}><Link component={RouterLink} to="#types-acf-sequence">Acf Sequence</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} xl={2}><Link component={RouterLink} to="#types-subscribe-form">Subscribe Form</Link></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-bottom-navigation">Bottom Navigation</Typography>
                <Typography variant="body1" gutterBottom>
                    The Bottom Navigation Component implements a page footer,
                    intended to be used for navigation. Bottom Navigation is
                    primarily a cosmetic Component.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="body1" gutterBottom>
                    The Bottom Navigation Component has no properties.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-template-fill">Template Fill</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-grid-container">Grid Container</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-rich-text">Text</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-container">Container</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-subscribe-form">Subscribe Form</Link></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-nav-drawer">Nav Drawer</Typography>
                <Typography variant="body1" gutterBottom>
                    The Nav Drawer Component implements a collapsible navigation
                    drawer. 
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="subtitle1">Permanent</Typography>
                <Typography variant="body1" gutterBottom>
                    The permanent property determines whether the Navigation
                    Drawer is collapsible or permanently opened.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-nav-drawer-item">Nav Drawer Item</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-nav-drawer-group">Nav Drawer Group</Link></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-nav-drawer-item">Nav Drawer Item</Typography>
                <Typography variant="body1" gutterBottom>
                    The Nav Drawer Item Component implements a link item in a
                    Nav Drawer.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="subtitle1" className={docClasses.subheading}>Text</Typography>
                <Typography variant="body1" gutterBottom>
                    The text to show as the Nav Drawer Item content.
                </Typography>
                <Typography variant="subtitle1" className={docClasses.subheading}>Link</Typography>
                <Typography variant="body1" gutterBottom>
                    The the URL that the Nav Drawer item links to.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Typography variant="body1" gutterBottom>
                    The Nav Drawer Item Component accepts no children.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-nav-drawer-group">Nav Drawer Group</Typography>
                <Typography variant="body1" gutterBottom>
                    The Nav Drawer Group Component is used to group multiple Nav
                    Drawer Items together. The group is collapsible, and has
                    its own label.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="subtitle1" className={docClasses.subheading}>Text</Typography>
                <Typography variant="body1" gutterBottom>
                    The label for the Nav Drawer Group.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-nav-drawer-item">Nav Drawer item</Link></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-app-bar">App Bar</Typography>
                <Typography variant="body1" gutterBottom>
                    The App Bar Component implements an app bar (the bar across
                    the top of the page). Like the Bottom Navigation, there
                    should only be one App Bar on a Page, and should be
                    consistent across all Pages.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="subtitle1">Heading</Typography>
                <Typography variant="body1" gutterBottom>
                    The heading to show in the App Bar. Usually the name of the
                    site.
                </Typography>
                <Typography variant="subtitle1">Menu button</Typography>
                <Typography variant="body1" gutterBottom>
                    Whether the App Bar should have a menu button to open a
                    Nav Drawer.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Typography variant="body1" gutterBottom>
                    The App Bar Component accepts no children.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-paper">Paper</Typography>
                <Typography variant="body1" gutterBottom>
                    The Paper Component is used to make a "card" or a box, which
                    has the appearance of being raised above the rest of the
                    Page content.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="subtitle1">Elevation</Typography>
                <Typography variant="body1" gutterBottom>
                    The apparent elevation of the Paper.
                </Typography>
                <Typography variant="subtitle1">Square</Typography>
                <Typography variant="body1" gutterBottom>
                    Whether the Paper has square or rounded corners.
                </Typography>
                <Typography variant="subtitle1">Variant</Typography>
                <Typography variant="body1" gutterBottom>
                    The two Paper variants are elevated and outlined. Elevated
                    Paper Components appear to be raised off of their
                    background. The apparent height of the Paper is determined
                    by the <code>elevation</code> property. Outlined Paper
                    Components are not elevated, and are instead identified
                    by an outline.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-template-fill">Template Fill</Link></Grid>1
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-grid-container">Grid Container</Link></Grid>2
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-divider">Divider</Link></Grid>12
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-heading">Heading</Link></Grid>15
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-image-carousel">Image Carousel</Link></Grid>18
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-block-quote">Block Quote</Link></Grid>19
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-rich-text">Text</Link></Grid>20
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-projects-by-year">Projects By Year</Link></Grid>26
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-acf-sequence">Acf Sequence</Link></Grid>28
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-subscribe-form">Subscribe Form</Link></Grid>30
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-expansion-panel-group">Expansion Panel Group</Typography>
                <Typography variant="body1" gutterBottom>
                    The Expansion Panel Group Component is used to group
                    together Expansion Panel Components.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="subtitle1">Controlled</Typography>
                <Typography variant="body1" gutterBottom>
                    When an Expansion Panel Group is controlled, only one child
                    Expansion Panel Item can be expanded at a time. Expanding
                    another item will collapse the previously expanded item.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-expansion-panel-item">Expansion Panel Item</Link></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-expansion-panel-item">Expansion Panel Item</Typography>
                <Typography variant="body1" gutterBottom>
                    The Expansion Panel Item Component is a container that
                    allows viewers to collapse or expand its contents.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="subtitle1">Title</Typography>
                <Typography variant="body1" gutterBottom>
                    The title of the Expansion Panel Item to show when the item
                    is collapsed.
                </Typography>
                <Typography variant="subtitle1">Expanded by default</Typography>
                <Typography variant="body1" gutterBottom>
                    Whether the Expansion Panel Item should be expanded by
                    default. Only one item can be expanded in a controlled
                    Expansion Panel Group.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-template-fill">Template Fill</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-image">Image</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-image-gallery">Image Gallery</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-block-quote">Block Quote</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-rich-text">Text</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-projects-by-year">Projects By Year</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-acf-sequence">Acf Sequence</Link></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-divider">Divider</Typography>
                <Typography variant="body1" gutterBottom>
                    The Divider is a stylistic Component that is very similar to
                    a Heading. The Divider's appearance is such that it creates
                    more of a visible division between two sections of content.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="subtitle1">Title</Typography>
                <Typography variant="body1" gutterBottom>
                    The title is displayed in the center of the divider, and
                    usually is the title of the content following the Divider.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Typography variant="body1"gutterBottom>
                    The Divider Component accepts no child components.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-heading">Heading</Typography>
                <Typography variant="body1" gutterBottom>
                    The Heading Component is a heading for content that follows
                    it.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="subtitle1">Variant</Typography>
                <Typography variant="body1" gutterBottom>
                    The variant of a Heading determines the 'level' or size of
                    the heading. A top-level heading is an h1, a second-level
                    heading is an h2, etc.
                </Typography>
                <Typography variant="subtitle1">Content</Typography>
                <Typography variant="body1" gutterBottom>
                    The text of the heading. The heading content can use rich
                    text References (see <Link component={RouterLink} to="#types-rich-text">
                    Rich Text</Link>).
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Typography variant="body1" gutterBottom>
                    The Heading Component accepts no child components.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Heading Components are unique in that they create Page
                    Anchors. Page Anchors can be used in other Pages to link
                    to a specific part of a Page.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-image">Image</Typography>
                <Typography variant="body1" gutterBottom>
                    The Image Component is used to insert an image into a Page.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="subtitle1">Image File</Typography>
                <Typography variant="body1" gutterBottom>
                    The Image File property determines the image File that is
                    shown by the Image Component.
                </Typography>
                <Typography variant="subtitle1">Internal Page Link</Typography>
                <Typography variant="body1" gutterBottom>
                    Internal Page Link is used to select a Page that the image
                    should link to.
                </Typography>
                <Typography variant="subtitle1">Label</Typography>
                <Typography variant="body1" gutterBottom>
                    Label is a short description of the contents of the image.
                </Typography>
                <Typography variant="subtitle1">External Link URL</Typography>
                <Typography variant="body1" gutterBottom>
                    External Link URL is similar to Internal Page Link, but used
                    to link to an external URL.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The Image Component accepts no children.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-image-gallery">Image Gallery</Typography>
                <Typography variant="body1" gutterBottom>
                    The Image Gallery Component is used to embed a collection of
                    images in a Page. Each image is shown as a thumbnail which
                    can be clicked on to open the image in full-screen.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="body1" gutterBottom>
                    The Image Gallery Component has no properties.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-image-gallery-item">Image Gallery Item</Link></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-image-gallery-item">Image Gallery Item</Typography>
                <Typography variant="body1" gutterBottom>
                    The Image Gallery Item Component represents an item in an
                    Image Gallery.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="subtitle1">Image File</Typography>
                <Typography variant="body1" gutterBottom>
                    The File of the image to show in the gallery.
                </Typography>
                <Typography variant="subtitle1">Label</Typography>
                <Typography variant="body1" gutterBottom>
                    A short description of the content of the image shown when
                    the image is opened.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Typography variant="body1" gutterBottom>
                    The Image Gallery Item Component accepts no children.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-image-carousel">Image Carousel</Typography>
                <Typography variant="body1" gutterBottom>
                    The Image Carousel Component displays a series of images,
                    one by one, switching between them with an animatied
                    transition.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="body1" gutterBottom>
                    The Image Carousel Component has no properties.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-image-carousel-item">Image Carousel Item</Link></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-image-carousel-item">Image Carousel Item</Typography>
                <Typography variant="body1" gutterBottom>
                    The Image Carousel Item Component represents an item in an
                    Image Carousel.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="subtitle1">Image</Typography>
                <Typography variant="body1" gutterBottom>
                    The Image property selects an image File to display in the
                    Image Carousel Item.
                </Typography>
                <Typography variant="subtitle1">Link to page</Typography>
                <Typography variant="body1" gutterBottom>
                    The Link to page property chooses a Page that the Image
                    Carousel Item image will link to.
                </Typography>
                <Typography variant="subtitle1">Title</Typography>
                <Typography variant="body1" gutterBottom>
                    The Image Carousel Item Title is the label that appears
                    when the user hovers over the image.
                </Typography>
                <Typography variant="subtitle1">content</Typography>
                <Typography variant="body1" gutterBottom>
                    The Image Carousel Item content is <Link component={RouterLink} to="#types-rich-text">
                    Rich Text</Link> content which is shown adjacent to the
                    image.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Typography variant="body1" gutterBottom>
                    The Image Carousel Item Component accepts no children.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-block-quote">Block Quote</Typography>
                <Typography variant="body1" gutterBottom>
                    The Block Quote Component is used to insert a heavily
                    emphasized and isolated quotation.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="subtitle1">Quotation</Typography>
                <Typography variant="body1" gutterBottom>
                    The text content of the quotation.
                </Typography>
                <Typography variant="subtitle1">Attribution</Typography>
                <Typography variant="body1" gutterBottom>
                    Who the quotation is attributed to.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Typography variant="body1" gutterBottom>
                    The Block Quote Component accepts no children.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-rich-text">Text</Typography>
                <Typography variant="body1" gutterBottom>
                    The Text Component is the main component used to insert rich
                    text content.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Rich Text</Typography>
                <Typography variant="body1" gutterBottom>
                    The Text Component is used to insert rich text. The
                    following 'rich' text content is available.
                </Typography>
                <Typography variant="subtitle1">Heading</Typography>
                <Typography variant="body1" gutterBottom>
                    Text in the Text Component can be formatted as a heading.
                    The same 6 heading 'levels' available in the <Link component={RouterLink} to="#types-heading">
                    Heading</Link> are available in the Text Component. The
                    main difference between using a Heading Component and using
                    a heading in a Text is spacing between the heading and the
                    surrounding Text content. Headings in Text Components also
                    do not create Page Anchors.
                </Typography>
                <Typography variant="subtitle1">Block Quote</Typography>
                <Typography variant="body1" gutterBottom>
                    Text Component text can also be formatted as Block Quote.
                    Unlike the <Link component={RouterLink} to="#types-block-quote">
                    Block Quote Component</Link>, block quote rich text does not
                    have an attribution.
                </Typography>
                <Typography variant="subtitle1">Alignment</Typography>
                <Typography variant="body1" gutterBottom>
                    Text Component content is divided into paragraphs. Each
                    paragraph can have its alignment set to either left, center,
                    right, or justified.
                </Typography>
                <Typography variant="subtitle1">Bold, Italics, Underline, Strikethrough, Code</Typography>
                <Typography variant="body1" gutterBottom>
                    Text Component content can have bold, italic, underline,
                    strikethrough, and code styling applied to arbitrary spans
                    of text. Note that the code styling is only styling and does
                    not insert arbitrary HTML into the Page.
                </Typography>
                <Typography variant="subtitle1">Table</Typography>
                <Typography variant="body1" gutterBottom>
                    Text Components can include tables in their rich content.
                    Pressing the Table button inserts a table at the location
                    of the cursor. Right click on the table to add or remove
                    columns and rows.
                </Typography>
                <Typography variant="subtitle1">Lists</Typography>
                <Typography variant="body1" gutterBottom>
                    The rich text content supports numbered and bulletted lists.
                    Lists can be nested using the indent and outdent.
                </Typography>
                <Typography variant="subtitle1">Links</Typography>
                <Typography variant="body1" gutterBottom>
                    Text can be wrapped in links to internal Pages, internal
                    Files, or arbitrary URLs.
                </Typography>
                <Typography variant="subtitle1">References</Typography>
                <Typography variant="body1" gutterBottom>
                    Rich Text References allow inserting values derived from
                    other resources. This allows a Page to include values that
                    are either calculated from other values, or that can be
                    changed without the Page having to be updated manually.
                    For example, an Organization Projects Reference inserts a
                    list of all of the Projects of an Organization. If a new
                    Project is added to the Organization, the Page will
                    automatically be updated to include the new Project. A
                    Funding Total by Year Reference will show the total funded
                    dollar amount for a grant-year, calculated automatically
                    from all of the Project Funding Records for the specified
                    year.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-container">Container</Typography>
                <Typography variant="body1" gutterBottom>
                    The Container Component defines margins for its contents
                    that adapt to different device sizes.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="body1" gutterBottom>
                    The Container Component has no properties.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-template-fill">Template Fill</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-grid-container">Grid Container</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-paper">Paper</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-expansion-panel-group">Expansion Panel Group</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-divider">Divider</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-heading">Heading</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-image">Image</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-image-gallery">Image Gallery</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-image-carousel">Image Carousel</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-block-quote">Block Quote</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-rich-text">Text</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-projects-by-year">Projects by Year</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-acf-sequence">Acf Sequence</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-subscribe-form">Subscribe Form</Link></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-projects-by-year">Projects by Year</Typography>
                <Typography variant="body1" gutterBottom>
                    The Projects by Year Component inserts a table containing
                    every Project that has a Funding Record for a specified
                    grant-year.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="subtitle1">Year</Typography>
                <Typography variant="body1" gutterBottom>
                    The grant-year to show funded Projects from.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Typography variant="body1" gutterBottom>
                    The Projects by Year Component accepts no children.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-acf-section">Acf Section</Typography>
                <Typography variant="body1" gutterBottom>
                    The Acf Section Component wraps its content in ACF-specific
                    styling. This includes the blue and green 'textured'
                    backgrounds seen on the home page.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="subtitle1">Color</Typography>
                <Typography variant="body1" gutterBottom>
                    The Color property is used to select between the blue and
                    green background options.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-template-fill">Template Fill</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-grid-container">Grid Container</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-paper">Paper</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-expansion-panel-group">Expansion Panel Group</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-divider">Divider</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-heading">Heading</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-image">Image</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-image-gallery">Image Gallery</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-image-carousel">Image Carousel</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-block-quote">Block Quote</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-rich-text">Text</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-container">Container</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-projects-by-year">Projects by Year</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-acf-sequence">Acf Sequence</Link></Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-subscribe-form">Subscribe Form</Link></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-acf-sequence">Acf Sequence</Typography>
                <Typography variant="body1" gutterBottom>
                    The Acf Sequence Component is used to create a numbered
                    sequence with ACF specific styling.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="body1" gutterBottom>
                    The Acf Sequence Container Component has no properties.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={4} md={3} lg={2}><Link component={RouterLink} to="#types-acf-sequence-item">Acf Sequence Item</Link></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-acf-sequence-item">Acf Sequence Item</Typography>
                <Typography variant="body1" gutterBottom>
                    The Acf Sequence Item Component is used with the Acf
                    Sequence Container Component to create an ACF styled
                    numbered sequence.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="subtitle1">Title</Typography>
                <Typography variant="body1" gutterBottom>
                    The title of the sequence item.
                </Typography>
                <Typography variant="subtitle1">content</Typography>
                <Typography variant="body1" gutterBottom>
                    The content of an Acf Sequence Item is a subset of the rich
                    text in a <Link component={RouterLink} to="#types-rich-text">
                    Text</Link> Component.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Typography variant="body1" gutterBottom>
                    The Acf Sequence Item Component accepts no children.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={docClasses.divider}/>
                <Typography variant="h5" id="types-subscribe-form">Subscribe Form</Typography>
                <Typography variant="body1" gutterBottom>
                    The Subscribe Form Component lets users subscribe themselves
                    for email notifications.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Properties</Typography>
                <Typography variant="subtitle1">Title</Typography>
                <Typography variant="body1" gutterBottom>
                    The title of the form.
                </Typography>
                <Typography variant="subtitle1">Description</Typography>
                <Typography variant="body1" gutterBottom>
                    A short description of what the user is subscribing to.
                </Typography>
                <Typography variant="subtitle1">Mailing List</Typography>
                <Typography variant="body1" gutterBottom>
                    The <Link component={RouterLink} to="/admin/docs/mail/mailing-list">
                    Mailing List</Link> that the subscribing users's email
                    address will be added to.
                </Typography>
                <Typography variant="h6" className={docClasses.subheading}>Child Components</Typography>
                <Typography variant="body1" gutterBottom>
                    The Subscribe Form Component accepts no children.
                </Typography>
            </Grid>
        </Grid>
    );
};

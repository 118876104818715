import React from 'react';
import { useHistory } from 'react-router-dom';

import { ConfirmSignup } from '@acf-site/react-sdk/components/Auth/ConfirmSignup';

import Container from '@material-ui/core/Container';

const Page: React.FunctionComponent = (): React.ReactElement => {
    React.useEffect(() => {
        document.title = "Confirm Sign Up - ACF Admin";
    });
    
    const history = useHistory();
    
    return (
        <Container maxWidth="sm">
            <ConfirmSignup onComplete={() => history.push(`/auth/`) }/>
        </Container>
    );
};

export default Page;

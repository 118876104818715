import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { DocIndex, DocletDocs, DocletIndex } from './../../pages/docs/';
import { DocAppBar } from './../../pages/docs/appbar';
import { DocPages, DocletPageBrowser, DocletPageDetail, DocletPageEditor, DocletTemplateBrowser, DocletTemplateEditor } from './../../pages/docs/pages';
import { DocEditor } from './../../pages/docs/editor';
import { DocFiles, DocletFileExplorer } from './../../pages/docs/files';
import { DocOrgs, DocletOrgBrowser, DocletOrgDetail, DocletProjectDetail } from './../../pages/docs/orgs';
import { DocMail, DocletMailHome, DocletEmailBrowser, DocletEmailDetail, DocletEmailEditor, DocletEmailTemplateBrowser, DocletEmailTemplateDetail, DocletEmailTemplateEditor, DocletListBrowser, DocletListDetail, DocletSendBrowser, DocletSendDetail } from './../../pages/docs/mail';
import { DocTasks, DocletTaskBrowser, DocletTaskDetail } from './../../pages/docs/tasks';
import { DocUsers, DocletUserBrowser, DocletUserDetail } from './../../pages/docs/users';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            
        }
    })
);

const DocsPageRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment>
            <Switch>
                <Route path="/admin/docs/pages" render={() => <DocPages/>}/>
                <Route path="/admin/docs/appbar" render={() => <DocAppBar/>}/>
                <Route path="/admin/docs/editor" render={() => <DocEditor/>}/>
                <Route path="/admin/docs/files" render={() => <DocFiles/>}/>
                <Route path="/admin/docs/orgs" render={() => <DocOrgs/>}/>
                <Route path="/admin/docs/mail" render={() => <DocMail/>}/>
                <Route path="/admin/docs/tasks" render={() => <DocTasks/>}/>
                <Route path="/admin/docs/users" render={() => <DocUsers/>}/>
                <Route path="/admin/docs" exact render={() => <DocIndex/>}/>
            </Switch>
        </React.Fragment>
    );
};

const DocletRoutes: React.FunctionComponent = (): React.ReactElement => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <div className={classes.root}>
                <Switch>
                    <Route path="/admin/pages/browse" render={() => <DocletPageBrowser/>}/>
                    <Route path="/admin/pages/page" render={() => <DocletPageDetail/>}/>
                    <Route path="/admin/pages/draft" render={() => <DocletPageEditor/>}/>
                    <Route path="/admin/pages/templates" render={() => <DocletTemplateBrowser/>}/>
                    <Route path="/admin/pages/template" render={() => <DocletTemplateEditor/>}/>
                    <Route path="/admin/files/browse" render={() => <DocletFileExplorer/>}/>
                    <Route path="/admin/orgs/browse" render={() => <DocletOrgBrowser/>}/>
                    <Route path="/admin/orgs/organization" render={() => <DocletOrgDetail/>}/>
                    <Route path="/admin/orgs/project" render={() => <DocletProjectDetail/>}/>
                    <Route path="/admin/mail/" exact render={() => <DocletMailHome/>}/>
                    <Route path="/admin/mail/emails" render={() => <DocletEmailBrowser/>}/>
                    <Route path="/admin/mail/email" render={() => <DocletEmailDetail/>}/>
                    <Route path="/admin/mail/emailDraft" render={() => <DocletEmailEditor/>}/>
                    <Route path="/admin/mail/templates" render={() => <DocletEmailTemplateBrowser/>}/>
                    <Route path="/admin/mail/template" render={() => <DocletEmailTemplateDetail/>}/>
                    <Route path="/admin/mail/templateDraft" render={() => <DocletEmailTemplateEditor/>}/>
                    <Route path="/admin/mail/lists" render={() => <DocletListBrowser/>}/>
                    <Route path="/admin/mail/list" render={() => <DocletListDetail/>}/>
                    <Route path="/admin/mail/sends" render={() => <DocletSendBrowser/>}/>
                    <Route path="/admin/mail/send" render={() => <DocletSendDetail/>}/>
                    <Route path="/admin/common/tasks" render={() => <DocletTaskBrowser/>}/>
                    <Route path="/admin/common/task" render={() => <DocletTaskDetail/>}/>
                    <Route path="/admin/auth/users" render={() => <DocletUserBrowser/>}/>
                    <Route path="/admin/auth/user" render={() => <DocletUserDetail/>}/>
                    <Route path="/admin/docs/" render={() => <DocletDocs/>}/>
                    <Route path="/admin/" exact render={() => <DocletIndex/>}/>
                </Switch>
            </div>
        </React.Fragment>
    );
};

export { DocsPageRoutes as PageRoutes, DocletRoutes };

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { CreateUserDialog } from '@acf-site/react-sdk/components/Auth/CreateUserDialog';
import { DisableUserDialog } from '@acf-site/react-sdk/components/Auth/DisableUserDialog';
import { EnableUserDialog } from '@acf-site/react-sdk/components/Auth/EnableUserDialog';
import { DeleteUserDialog } from '@acf-site/react-sdk/components/Auth/DeleteUserDialog';

import UserBrowserPage from './../../pages/admin/auth/userBrowse';
import UserDetailPage from './../../pages/admin/auth/userDetail';

const AuthPageRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment>
            <CreateUserDialog/>
            <DisableUserDialog/>
            <EnableUserDialog/>
            <DeleteUserDialog/>
            <Switch>
                <Route path="/admin/auth/users/" render={() => <UserBrowserPage/>}/>
                <Route path="/admin/auth/user/" render={() => <UserDetailPage/>}/>
            </Switch>
        </React.Fragment>
    );
};

const AuthAppBarRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment></React.Fragment>
    );
};

const AuthNavDrawerRoutes: React.FunctionComponent = (): React.ReactElement => {
    return (
        <React.Fragment></React.Fragment>
    );
};

export {
    AuthPageRoutes as PageRoutes,
    AuthAppBarRoutes as AppBarRoutes,
    AuthNavDrawerRoutes as NavDrawerRoutes
};

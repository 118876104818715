import React from 'react';

import { SignIn } from '@acf-site/react-sdk/components/Auth/SignIn';

import Container from '@material-ui/core/Container';

const Page: React.FunctionComponent = (): React.ReactElement => {
    React.useEffect(() => {
        document.title = "Sign In - ACF Admin";
    });
    
    return (
        <Container maxWidth="sm">
            <SignIn/>
        </Container>
    );
};

export default Page;
